import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {NotificationManager} from 'react-notifications';

import ClientSelector from './client-selector.jsx';
import './admin-styles.scss';
import _ from 'lodash';
import AuthService from '../../services/auth-service.js';
import ConfirmationModal from '../Modals/confirmation-modal.jsx';
import ProjectAdd from './project-add.jsx';

class ProjectsList extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isNewProjectFormVisible: false,
            deleteModalActive: false,
            deleteProjectBK: null,
            deleteModalText: 'Are you sure?'
        };
        this.updateClient = this.updateClient.bind(this);
        this.gotoUsers = this.gotoUsers.bind(this);
        this.gotoCalculations = this.gotoCalculations.bind(this);

        this.deleteProject = this.deleteProject.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.toggleNewProjectForm = this.toggleNewProjectForm.bind(this);
    }

    updateClient(resp) {
        if (resp.data && resp.data.businessKey) {
            this.props.history.push(
                `/admin/clients/${resp.data.businessKey}/projects`
            );
        }
    }

    toggleNewProjectForm(hide) {
        const isNewProjectFormVisible = hide !== true;
        this.setState({isNewProjectFormVisible});
    }

    gotoUsers(clientBK, projectBK) {
        this.props.history.push(`/admin/clients/${clientBK}/projects/${projectBK}/users`);
    }

    gotoCalculations(event, projectBK) {
        event.stopPropagation();
        this.props.history.push(`/admin/clients/${this.props.clientBK}/projects/${projectBK}/calculations`);
    }

    deleteProject() {
        if (!_.isEmpty(this.state.deleteProjectBK)) {
            return window.FastcastService().deleteProject(
                AuthService.getToken(), this.state.deleteProjectBK).then(async () => {
                await this.props.refreshClientProjects(this.props.clientBK);
            }).catch(() => {
                NotificationManager.error(
                    'Please remove calculations and users under this project before deleting it.', '', 8000);
            }).finally(() => {
                this.setState({
                    deleteModalActive: false
                });
            });
        }
    }

    hideDeleteModal(event, show, deleteProjectBK) {
        this.setState({
            deleteModalActive: (show) ? true : false,
            deleteProjectBK
        });
    }

    render() {
        return (
            <Fragment>
                <ConfirmationModal
                    isVisible={this.state.deleteModalActive}
                    modalText={this.state.deleteModalText}
                    yesHandler={this.deleteProject}
                    noHandler={this.hideDeleteModal}
                />
                <div className='new-item-container'>
                    {this.props.clientBK && (
                        <button type='button' className='btn btn-primary' onClick={this.toggleNewProjectForm}>New Project</button>
                    )}
                    <ProjectAdd display={this.state.isNewProjectFormVisible}
                        hideForm={this.toggleNewProjectForm}
                        clientBK={this.props.clientBK}
                        refreshProjects={() => {this.props.refreshClientProjects(this.props.clientBK);}}
                    />
                </div>
                <ClientSelector clientBK={this.props.clientBK} newClientHandler={this.updateClient}/>

                <table className={'table'}>
                    <thead>
                        <tr>
                            <th scope='col'>Business Key</th>
                            <th scope='col'>Name</th>
                            <th scope='col'>Active</th>
                            <th scope='col'>Calculations</th>
                            <th scope='col'>Go to</th>
                            <th scope='col'>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.projects.map((project) => {
                            return (
                                <tr key={project.businessKey}>
                                    <td>{project.businessKey}</td>
                                    <td>{project.name}</td>
                                    <td>
                                        <input className='form-check-input'
                                            type='checkbox' value={project.active}
                                            id={project.businessKey}
                                            defaultChecked={project.active}
                                        />
                                    </td>
                                    <td>
                                        <button className={'btn btn-primary'}
                                            onClick={(e) => {this.gotoCalculations(e, project.businessKey);}}
                                        >
                                            Calculations
                                        </button>
                                    </td>
                                    <td>
                                        <button className='btn btn-primary margin-left-20'
                                            onClick={() => {this.gotoUsers(this.props.clientBK, project.businessKey);}}>
                                            Users
                                        </button>
                                    </td>
                                    <td>
                                        <button className='btn btn-danger'
                                            onClick={(e) => {this.hideDeleteModal(e, true, project.businessKey);}}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Fragment>
        );
    }

}

ProjectsList.prototypes = {
    clients: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    clientBK: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    refreshClientProjects: PropTypes.func.isRequired
};
export default ProjectsList;
