export const PAGES = {
    ADMIN: 'admin',
    CONTACT: 'contact',
    HOME: '',
    TOOLS: 'tools',
    SF_PARKS: 'sf-parks'
};

export const ADMIN = {
    CLIENTS: 'clients',
    PROJECTS: 'projects',
    USERS: 'users',
    CALCULATIONS: 'calculations',
    CLIENT_USERS: 'client-users',
    PROJECT_USERS: 'project-users'
};

export const USER_ROLES = {
    READ_ONLY: 'READ_ONLY',
    USER: 'USER',
    CLIENT_USER: 'CLIENT_USER',
    CLIENT_ADMIN: 'CLIENT_ADMIN',
    SUPER_ADMIN: 'SUPER_ADMIN'
};

export const ROLE_ORDER = {
    READ_ONLY: 1,
    CLIENT_USER: 2,
    CLIENT_ADMIN: 3,
    SUPER_ADMIN: 4
};

export const ROLE_BY_INDEX = {
    1: USER_ROLES.READ_ONLY,
    2: USER_ROLES.CLIENT_USER,
    3: USER_ROLES.CLIENT_ADMIN,
    4: USER_ROLES.SUPER_ADMIN
};

export const FC_PATH = {
    DEV: 'fastcastcity.net',
    PROD: 'fastcastcity.com'
};
