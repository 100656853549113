import React, { PureComponent, Fragment } from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import _ from 'lodash';
import axios from 'axios';
import classNames from 'classnames';
import {NotificationManager} from 'react-notifications';

import {esriService} from '@fastcast/lib';
import UtilityService from '../../services/utility-service.js';
import AuthService from '../../services/auth-service.js';

import './projects-main-panel.scss';

const DEFAULT_LOCATION = {
    parcelId: '',
    centroidHeight: 0,
    centroidLongitude: 0,
    centroidLatitude: 0,
    address: {
        street: '',
        city: 'San Francisco',
        state: 'CA'
    }
};

class ProjectsMainPanel extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            areEsriModulesLoaded: false,
            isFanEnabled: false,
            isMapCleared: false,
            newLocationElevation: 0,
            newLocationGeo: DEFAULT_LOCATION,
            netNewQueued: false
        };

        this.handleFormFieldUpdate = this.handleFormFieldUpdate.bind(this);
        this.handleNetNew = this.handleNetNew.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.mapClickEvents = this.mapClickEvents.bind(this);
        this.showNetNew = this.showNetNew.bind(this);
    }

    async componentDidMount() {
        await this.loadEsriModulesAndRenderMap();
    }

    async componentDidUpdate(prevProps) {
        if (!this.props.activateNewCalculation &&
            !_.isEmpty(_.xor(prevProps.selectedCalculationBKs, this.props.selectedCalculationBKs))
        ) {
            // await this.refreshParcelRender();
            this.props.reloadMapCall(true);
        }

        if (this.props.activateNewCalculation && !prevProps.activateNewCalculation) {
            this.activateNewCalculation(this.props.activateNewCalculation);
        }

        if (this.props.reloadMap && !prevProps.reloadMap) {
            await this.refreshParcelRender();
            this.props.reloadMapCall(false);
        }
    }

    resetMap() {
        esriService.parcelClickEnabled(false);
        esriService.clearMapGraphics();
        esriService.clearNetNewMapGraphics();
    }

    getAxios(url) {
        return axios({
            method: 'GET',
            url: url,
            responseEncoding: 'utf8'
        });
    }

    getCalculationsFromProject(projectBK, calculationBKs) {
        return _.filter(this.props.projectCalculations[projectBK], (calc) => {
            return calculationBKs.includes(calc.businessKey);
        });
    }

    async refreshParcelRender() {
        try {
            this.props.renderLoader(true);
            await this.loadEsriModulesAndRenderMap();

            this.resetMap();
            if (_.isEmpty(this.props.selectedCalculationBKs)) {
                return;
            }

            const calculations = this.getCalculationsFromProject(
                this.props.activeProjectBK, this.props.selectedCalculationBKs);

            if (_.isEmpty(this.props.selectedCalculationBKs) || _.isEmpty(calculations)) {
                return;
            }
            const metadata = UtilityService.parseJSON(calculations[0].metadata);
            await esriService.makeParcelCurrent(metadata.parcelId);
            const street = (metadata.address && metadata.address.street) ? metadata.address.street : ' ';
            esriService.displaySearchTerm(street);

            calculations.forEach((cal) => {
                if (cal.agolJson) {
                    esriService.clearNetNewMapGraphics();
                    esriService.displayPolygon(cal.agolJson, cal.maxHeight);
                }
                if (cal.netNew.status === 'COMPLETED') {
                    let netNewPromises = [];
                    for  (const comp of cal.netNew.netNewComputations) {
                        netNewPromises.push(this.getAxios(comp.dxf));
                        netNewPromises.push(this.getAxios(comp.csv));
                    }
                    let netNewArtifacts = [];
                    Promise.all(netNewPromises).then((responses) => {
                        for (let i = 0; i < responses.length ; i += 2) {
                            const regex = /(db\/.*?\.dxf)/g;
                            const txt = (responses[i+1].data).match(regex);
                            let parkName = '';
                            if (!_.isEmpty(txt) && !_.isNil(txt[0])) {
                                parkName = txt[0].substring(3, (txt[0].length - 4));
                                parkName = _.startCase(parkName);
                            }
                            netNewArtifacts.push({
                                dxfData: responses[i].data,
                                csvData: responses[i+1].data,
                                parkName
                            });
                        }
                        if (netNewArtifacts.length > 0) {
                            const parcelId = UtilityService.parseJSON(cal.metadata).parcelId;
                            const height = cal.maxHeight || 100;
                            esriService.renderNetNew(parcelId, height, netNewArtifacts);
                        }
                    }).catch(error => {
                        console.error('Unexpected error occurred while rendering net new', error);
                    });
                }
            });
        } catch (error) {
            console.error('Unexpected error occurred while rendering a parcel', error);
        } finally {
            this.props.renderLoader(false);
        }
    }

    handleFormFieldUpdate(event, fieldname) {
        if (fieldname === 'elevation') {
            this.setState({
                newLocationElevation: event.target.value
            });
        } else {
            return;
        }
        event.stopPropagation();
        this.updateFanButtonState();
    }

    updateFanButtonState() {
        const disableSubmit = _.isNil(this.state.newLocationElevation) ||
            this.state.newLocationElevation <= 0 ||
            _.isEmpty(this.state.newLocationGeo.parcelId) ||
            _.isNil(this.state.newLocationGeo.centroidHeight) ||
            _.isNil(this.state.newLocationGeo.centroidLatitude) ||
            _.isNil(this.state.newLocationGeo.centroidLongitude);
        this.setState({
            isFanEnabled: !disableSubmit
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.props.renderLoader(true);
        // let newCalculation;
        try {
            this.setState({isFanEnabled: false});
            const newCalculation = await UtilityService.computeNewFan(
                this.props.activeProjectBK,
                this.props.projects[0].clientBusinessKey,
                this.state.newLocationElevation,
                this.state.newLocationGeo
            );
            await this.props.updateProjectsAndCalculations(this.props.activeProjectBK, [newCalculation.businessKey], false, true);
            this.props.addFanCalculationsInProgress(newCalculation.businessKey);
        } catch (error) {
            console.error('Failed in process FAN calculation', error);
            NotificationManager.error('Your Fan calculation has errored out.', '', 8000);
        } finally {
            this.props.renderLoader(false);
        }
    }

    clearMap(clear) {
        this.setState({
            isMapCleared: clear
        });
    }

    activateNewCalculation(activate) {
        this.setState({netNewQueued: false});
        if (!this.state.isMapCleared) {
            esriService.clearMapGraphics();
            this.clearMap(true);
        }
        this.props.renderLoader(true);
        const calculations = this.props.projectCalculations[this.props.activeProjectBK];
        if (activate && calculations.length > 0) {
            esriService.clearMapGraphics();
            esriService.parcelClickEnabled(false);
            const metadata = UtilityService.parseJSON(calculations[0].metadata);
            esriService.makeParcelCurrent(metadata.parcelId);
            this.setState({
                newLocationElevation: 0
            });
        } else {
            this.setState({
                newLocationElevation: 0
            });
            esriService.clearMapGraphics();
            esriService.parcelClickEnabled(activate);
        }
        this.props.triggerNewCalculation(activate);
        this.props.renderLoader(false);
    }

    async handleNetNew(event) {
        event.preventDefault();

        if (this.props.selectedCalculationBKs && this.props.selectedCalculationBKs.length === 1) {
            this.setState({netNewQueued: true});
            const projects = _.filter(this.props.projects, {businessKey: this.props.activeProjectBK});
            if (_.isEmpty(projects)) {
                console.error('Failed to find the project');
                return;
            }
            const clientKey = projects[0].clientBusinessKey;
            const projectKey = this.props.activeProjectBK;
            const calculationKey = this.props.selectedCalculationBKs[0];
            await window.FastcastService().queueNetNewCalculation(
                AuthService.getToken(), calculationKey, projectKey, clientKey
            );
            await this.props.updateProjectsAndCalculations(projectKey, null, false, true, true);
            this.setState({netNewQueued: false});
            NotificationManager.success('Your Net New calculation has been submitted to the queue.', '', 3000);
        }
    }

    showNetNew() {
        if (this.props.selectedCalculationBKs.length !== 1 || this.state.netNewQueued) {
            return false;
        }

        const calculations = this.getCalculationsFromProject(this.props.activeProjectBK, this.props.selectedCalculationBKs);
        return !_.isEmpty(calculations) && calculations[0].netNew.notStarted;
    }

    mapClickEvents(resp) {
        const {parcelId, centroidHeight, centroidLongitude, centroidLatitude, streetAddress} = resp;
        if (parcelId && centroidHeight && centroidLongitude && centroidLatitude && streetAddress) {
            let newLoc = _.cloneDeep(DEFAULT_LOCATION);
            newLoc.parcelId = parcelId;
            newLoc.centroidHeight = centroidHeight;
            newLoc.centroidLongitude = centroidLongitude;
            newLoc.centroidLatitude = centroidLatitude;
            newLoc.address.street = streetAddress;
            this.setState({
                newLocationGeo: newLoc
            });
            esriService.displaySearchTerm(resp.streetAddress);
            this.updateFanButtonState();
        }
    }

    async loadEsriModulesAndRenderMap() {
        return Promise.resolve().then(() => {
            this.props.renderLoader(true);
            if (!this.state.areEsriModulesLoaded) {
                return esriService.loadEsriModules();
            }
        }).then(() => {
            if (!this.state.areEsriModulesLoaded) {
                esriService.renderMapView('mapDiv');
                esriService.attachEventListener('gotParcel', this.mapClickEvents);
                esriService.parcelClickEnabled(false);
                this.setState({areEsriModulesLoaded: true});
            }
        }).finally(() => {
            // 200ms timeout for map to render
            return new Promise(resolve => setTimeout(resolve, 200));
        });
    }

    render() {
        const topMapForm = (<form onSubmit={this.handleSubmit}>
            <div className='element'>
                <strong>Parcel: </strong>
                <span className='element-span'>{this.state.newLocationGeo.parcelId}</span>
            </div>
            <div className='element'>
                <strong>Parcel Elevation (ft): </strong>
                <span className='element-span'>
                    {this.state.newLocationGeo.centroidHeight.toFixed(1)}
                </span>
            </div>
            {this.props.activateNewCalculation &&
            <div className={
                classNames({
                    'element': true,
                    'hide': !this.props.activateNewCalculation
                })
            }>
                <strong>Max Height (ft): </strong>
                <input className='elevation-input' type='number'
                    onChange={(event) => {
                        this.handleFormFieldUpdate(event, 'elevation');
                    }}
                    value={this.state.newLocationElevation}/>
            </div>
            }
            {this.showNetNew() && (
                <button className={
                    classNames({
                        'btn btn-primary rt-button': true,
                        'hide': !this.showNetNew()
                    })
                } onClick={(e) => {
                    this.handleNetNew(e);
                }} disabled={!this.showNetNew()}>
                    Net New
                </button>
            )}
            {this.props.activateNewCalculation && (
                <button type='submit' className={
                    classNames({
                        'btn btn-primary rt-button': true,
                        'hide': !this.props.activateNewCalculation
                    })
                } disabled={!this.state.isFanEnabled}>
                    Fan
                </button>
            )}
        </form>);
        return (
            <Fragment>
                <div id='mainDiv' className='map-container'>
                    <div className={classNames({
                        'map-info': true,
                        // 'hide': !this.props.activateNewCalculation
                    })} >
                        {topMapForm}
                    </div>
                    <div id='mapDiv' className='map-block' />
                </div>
            </Fragment>
        );
    }

}

ProjectsMainPanel.prototypes = {
    activeProjectBK: PropTypes.string,
    activateNewCalculation: PropTypes.bool.isRequired,
    projectCalculations: PropTypes.object.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    renderLoader: PropTypes.func.isRequired,
    reloadMap: PropTypes.bool.isRequired,
    reloadMapCall: PropTypes.func.isRequired,
    selectedCalculationBKs: PropTypes.arrayOf(PropTypes.string),
    updateProjectsAndCalculations: PropTypes.func.isRequired,
    triggerNewCalculation: PropTypes.func.isRequired,
    addFanCalculationsInProgress: PropTypes.func.isRequired
};

export default withRouter(ProjectsMainPanel);
