import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import './projects-side-bar.scss';

class ProjectsSideBarNew extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            newProjectActive: false,
            newProjectName: ''
        };
        this.handleFormFieldUpdate = this.handleFormFieldUpdate.bind(this);
        this.showNewProjectBox = this.showNewProjectBox.bind(this);
    }

    showNewProjectBox() {
        this.setState({
            newProjectActive: !this.state.newProjectActive
        });
    }


    handleFormFieldUpdate(event, fieldname) {
        event.stopPropagation();
        if (fieldname === 'project-name') {
            this.setState({
                newProjectName: event.target.value
            });
        } else {
            return;
        }
    }

    resetNewProject() {
        this.setState({
            newProjectActive: false,
            newProjectName: ''
        });
    }

    render() {
        return (
            <Fragment>
                <div className='sidebar-element new-project'>
                    <div className='add-link'
                        onClick={() => {
                            this.showNewProjectBox();
                        }}>
                        {this.props.projectType==='PARCEL' &&
                            <label className={'new-project'}><strong>+</strong> new parcel</label>
                        }
                        {this.props.projectType==='LAYOUT' &&
                        <label className={'new-project'}><strong>+</strong> new layout</label>
                        }
                    </div>
                    { this.state.newProjectActive && (
                        <>
                            <input type='text'
                                name='projectName'
                                className='project-name form-inline form-control-plaintext'
                                placeholder={!this.state.newProjectName ? 'Project name' : ''}
                                onChange={(event) => {
                                    this.handleFormFieldUpdate(event, 'project-name');
                                }}
                            />
                            <div className={'link-block'}>
                                <span className='link' onClick={async (e) => {
                                    await this.props.createProject(
                                        this.state.newProjectName,
                                        this.props.projectType,
                                        e
                                    );
                                    await this.resetNewProject();
                                }}>submit</span>
                            </div>
                        </>
                    )}
                </div>
            </Fragment>
        );
    }

}

ProjectsSideBarNew.propTypes = {
    createProject: PropTypes.func.isRequired,
    projectType: PropTypes.string.isRequired
};

export default withRouter(ProjectsSideBarNew);
