import _ from 'lodash';
import {USER_ROLES, ROLE_BY_INDEX, ROLE_ORDER} from '../constants/app-constants';

class AuthService {
    logout() {
        window.kc.logout();
    }

    login() {
        window.kc.login();
    }

    isUserAuthenticated() {
        return window.kc && window.kc.authenticated && !window.kc.isTokenExpired();
    }

    getToken() {
        this.checkAuthentication();
        return window.kc.token;
    }

    getUserId() {
        this.checkAuthentication();
        return window.kc.tokenParsed.sub;
    }

    hasRolesArray() {
        return _.hasIn(window, 'kc.realmAccess.roles') && !_.isEmpty(window.kc.realmAccess.roles);
    }

    hasMinimumReadRole() {
        if (!this.hasRolesArray()) {
            return false;
        }
        let highestRoleIndex = 0;
        const userRoles = window.kc.realmAccess.roles;
        userRoles.forEach(role => {
            if (ROLE_ORDER[role] > highestRoleIndex) {
                highestRoleIndex = ROLE_ORDER[role];
            }
        });
        return highestRoleIndex > 0;
    }

    checkAuthentication() {
        !this.isUserAuthenticated() && this.login();
    }

    canDelegateRoles(clientUser) {
        const clientUserRole = (!_.isNil(clientUser)) ? clientUser.userRole : null;
        this.checkAuthentication();
        let allowedRoles = [];
        const userRoles = window.kc.realmAccess.roles;

        let highestRoleIndex = 0;
        userRoles.forEach(role => {
            if (ROLE_ORDER[role] > highestRoleIndex) {
                highestRoleIndex = ROLE_ORDER[role];
            }
        });
        if (!_.isNil(clientUserRole) && highestRoleIndex > ROLE_ORDER[clientUserRole]) {
            highestRoleIndex = ROLE_ORDER[clientUserRole];
        }
        for (let i=1; i<=highestRoleIndex; i++) {
            allowedRoles.push(ROLE_BY_INDEX[i]);
        }

        if (userRoles.includes(USER_ROLES.CLIENT_ADMIN) && !userRoles.includes(USER_ROLES.SUPER_ADMIN)) {
            allowedRoles = _.without(allowedRoles, USER_ROLES.USER);
        }
        return allowedRoles;
    }

    isSuperAdmin() {
        this.checkAuthentication();
        if (!this.hasRolesArray()) {
            return false;
        }
        return window.kc.realmAccess.roles.includes(USER_ROLES.SUPER_ADMIN);
    }

    isClientAdmin() {
        this.checkAuthentication();
        if (!this.hasRolesArray()) {
            return false;
        }
        return window.kc.realmAccess.roles.includes(USER_ROLES.CLIENT_ADMIN);
    }

    getSignUpUrl(redirectUri) {
        if (redirectUri) {
            return window.kc.createRegisterUrl({redirectUri: redirectUri});
        } else {
            return window.kc.createRegisterUrl();
        }
    }
}

export default new AuthService();
