import React, { PureComponent, Fragment } from 'react';
import {Link} from 'react-router-dom';

import classNames from 'classnames';
import './nav-styles.scss';
import AuthService from '../../services/auth-service.js';
import * as PropTypes from 'prop-types';
import {PAGES} from '../../constants/app-constants.js';
import {withRouter} from 'react-router';

class Header extends PureComponent {

    render() {
        return (
            <Fragment>
                <nav className="navbar navbar-expand-md navbar-light fc-topnav-bar">
                    <div className="fc-logo" onClick={() => {
                        if(window.location.pathname !== '/') {
                            this.props.history.push(`/${PAGES.HOME}`);
                        }}}>
                        <Link to='/'>Fastcast</Link>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            {AuthService.isUserAuthenticated() &&
                            (AuthService.isSuperAdmin() || AuthService.isClientAdmin()) && (
                                <li className={classNames({
                                    'nav-item': true,
                                    'active': (this.props.active === PAGES.ADMIN)
                                })}>
                                    <Link to='/admin/clients'>Admin</Link>
                                </li>
                            )}
                            <li className={classNames({
                                'nav-item': true,
                                'active': (this.props.active === PAGES.CONTACT)
                            })}>
                                <Link to='/contact'>Contact Us</Link>
                            </li>
                            {AuthService.isUserAuthenticated() && AuthService.hasMinimumReadRole() && (
                                <li className={classNames({
                                    'nav-item': true,
                                    'active': (this.props.active === PAGES.TOOLS)
                                })}>
                                    <Link to='/projects'>Tools</Link>
                                </li>
                            )}
                            {AuthService.isUserAuthenticated() && (
                                <li className={classNames({
                                    'nav-item': true,
                                    'active': (this.props.active === PAGES.SF_PARKS)
                                })}>
                                    <Link to='/parks'>SF Parks</Link>
                                </li>
                            )}
                            {AuthService.isUserAuthenticated() ? (
                                <li className='nav-item' onClick={AuthService.logout}>
                                    Sign Out
                                </li>
                            ) : (
                                <li className='nav-item' onClick={AuthService.login}>
                                    Sign In
                                </li>)}
                        </ul>
                    </div>
                </nav>
            </Fragment>
        );
    }
}

Header.propTypes = {
    active: PropTypes.string.isRequired
};

export default withRouter(Header);
