import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import AuthService from '../../services/auth-service.js';

import './admin-styles.scss';

class UserAdd extends PureComponent {
    constructor(props) {
        super(props);
        this.dropdownChange = this.dropdownChange.bind(this);
        this.userBKChange = this.userBKChange.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {
            userRole: '',
            userBK: ''
        };
    }

    resetState() {
        this.setState({
            userRole: '',
            userBK: ''
        });
    }
    updateClientBK() {
        if (!_.isEmpty(this.props.clientBK)) {
            this.setState({
                searchType: 'businessKey',
                userBK: this.props.clientBK
            });
        }
    }

    userBKChange(event) {
        let val = '';
        if (!_.isNil(event.target.value)) {
            val = event.target.value;
        }
        this.setState({
            userBK: val
        });
    }

    dropdownChange(event) {
        let val = '';
        if (event.target.value && event.target.value !== 'default') {
            val = event.target.value;
        }
        this.setState({
            userRole: val
        });
    }

    submit(event) {
        this.props.showLoader(true);
        event.stopPropagation();
        if (!_.isEmpty(this.state.userRole) && !_.isEmpty(this.state.userBK)) {
            if (this.props.userType === 'client') {
                return window.FastcastService().postClientUser(
                    AuthService.getToken(), this.props.clientBK, this.state.userBK, this.state.userRole
                ).catch(() => {
                    NotificationManager.error(
                        'Please check if the user is already added to other client.', '', 8000);
                }).finally(() => {
                    this.props.refreshHandler();
                    this.resetState();
                    this.props.showLoader(false);
                });
            }
            this.props.showLoader(false);
        }
    }
    render() {
        return (
            <Fragment>
                <div className='dropdown-selector'>
                    <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                            <select className='custom-select' id='inputGroupSelect01'
                                onChange={this.dropdownChange} value={this.state.userRole}>
                                <option defaultValue='default'>User Role ...</option>
                                {AuthService.canDelegateRoles().map(role => {
                                    return (<option value={role} key={role}>{role}</option>);
                                })};
                            </select>
                        </div>
                        <input type='text' className='form-control' aria-label='Text input with dropdown button'
                            onChange={this.userBKChange} value={this.state.userBK} placeholder='User Business Key'/>
                        <div className='input-group-append'>
                            <button className='btn btn-primary' type='button'
                                disabled={!this.state.userRole || !this.state.userBK} onClick={this.submit}>
                                Create User
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

UserAdd.prototypes = {
    userType: PropTypes.string.isRequired,
    clientBK: PropTypes.string.isRequired,
    refreshHandler: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired
};
export default UserAdd;
