import React  from 'react';
import './app.scss';
import Routes from './routes/routes';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function App() {
    return (
        <div className="App">
            <NotificationContainer/>
            <Routes/>
        </div>
    );
}

export default App;
