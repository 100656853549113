import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import './index.css';
import App from './app';
import * as serviceWorker from './service-worker';

import Keycloak from './lib/keycloak.js';
import {FC_PATH} from './constants/app-constants';

import {fastcastService} from '@fastcast/lib';

const GA_TRACKING_ID = {
    PROD: 'UA-116939868-1',
    DEV: 'UA-116744095-1'
};

const KEYCLOAK_CONFIG_DEV = '/kc-dev.json';
const KEYCLOAK_CONFIG_PROD = '/kc-prod.json';

const KEYCLOAK_OPTIONS = {
    checkLoginIframe: false,
    onLoad: 'check-sso'
};

const isProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

window.isDevelopment = () => {
    return process.env.REACT_APP_ENV === 'development';
};

const fastcastServiceConfig = () => {
    return {
        fastcast: {
            authBaseUrl: process.env.REACT_APP_AUTH_BASE_URL,
            serviceBaseUrl: process.env.REACT_APP_FASTCAST_SERVICE_BASE_URL
        }
    };
};

window.FastcastServiceInstance = new fastcastService(fastcastServiceConfig());

window.FastcastService = () => {
    if (!window.FastcastServiceInstance) {
        window.FastcastServiceInstance = new fastcastService(fastcastServiceConfig());
    }
    return window.FastcastServiceInstance;
};

const initializeGA = () => {
    const host = window.location.host;
    let trackingId = null;
    if (host.includes(FC_PATH.PROD)) {
        trackingId = GA_TRACKING_ID.PROD;
    } else if (host.includes(FC_PATH.DEV)) {
        trackingId = GA_TRACKING_ID.DEV;
    } else {
        trackingId = null;
    }
    trackingId && ReactGA.initialize(trackingId);
};

const initializeApp = () => {
    ReactDOM.render(
        <App/>,
        document.getElementById('root')
    );
};

const initializeKC = () => {
    if (isProduction()) {
        window.kc = new Keycloak(KEYCLOAK_CONFIG_PROD);
    } else {
        window.kc = new Keycloak(KEYCLOAK_CONFIG_DEV);
    }

    window.kc.init(KEYCLOAK_OPTIONS).success(() => {
        initializeApp();
    }).error((err) => {
        console.error('Keycloak failed to initialize.', err);
    });
};

initializeKC();
initializeGA();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
