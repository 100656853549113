import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './modal.scss';

class ConfirmationModal extends PureComponent {

    render() {
        const customStyles = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)'
            }
        };
        return(
            <Fragment>
                <Modal isOpen={this.props.isVisible}
                    contentLabel={this.props.modalText}
                    styles={customStyles}
                >
                    <div className='modal-container'>
                        <div className='text'>
                            <h3>{this.props.modalText}</h3>
                        </div>
                        <div className='buttons'>
                            <button className='btn btn-primary rt-button' onClick={this.props.noHandler}>No</button>
                            <button className='btn btn-primary rt-button btn-spacing' onClick={this.props.yesHandler}>Yes</button>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

Modal.setAppElement('#root');

ConfirmationModal.prototypes = {
    isVisible: PropTypes.bool.isRequired,
    yesHandler: PropTypes.func.isRequired,
    noHandler: PropTypes.func.isRequired,
    modalText: PropTypes.string.isRequired
};

export default ConfirmationModal;
