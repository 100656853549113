import React, { PureComponent, Fragment } from 'react';

import './nav-styles.scss';
import * as PropTypes from 'prop-types';
import Header from './header.jsx';

class Footer extends PureComponent {

    render() {
        const date = new Date();
        const year = date.getFullYear();

        return (
            <Fragment>
                <div className='footer'>
                    <div className='footer-inner global--width'>
                        <p className='copyright'>
                            &copy; Fastcast {year} All Rights Reserved.
                        </p>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Header.propTypes = {
    active: PropTypes.string.isRequired
};

export default Footer;
