import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './mass-config.css';
import './mass-override.scss';

import {MassingComponent} from '@fastcast/massing-lib';

class MassingPanel extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
    }

    render() {
        return (
            <div className={`mc-container-ext ${!this.props.isVisible ? 'hidden' : ''}`}>
                <div className={'mc-container-background'} onClick={this.props.toggleMassingModalVisibility}/>
                <MassingComponent
                    accessToken={this.props.accessToken}
                    layoutBK={this.props.layoutBK}
                    calculationBK={this.props.calculationBK}
                />
            </div>
        );
    }
}

MassingPanel.prototypes = {
    accessToken: PropTypes.string.isRequired,
    layoutBK: PropTypes.string.isRequired,
    calculationBK: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    toggleMassingModalVisibility: PropTypes.func.isRequired
};

export default MassingPanel;
