import React, { PureComponent, Fragment } from 'react';
import {withRouter} from 'react-router';

import _ from 'lodash';
import queryString from 'query-string';
import {NotificationManager} from 'react-notifications';

import AuthService from '../../services/auth-service.js';
import UtilityService from '../../services/utility-service.js';

import {PAGES} from '../../constants/app-constants.js';

import Footer from '../NavPanels/footer.jsx';
import Header from '../NavPanels/header.jsx';
import Loader from '../Loader/loader.jsx';
import ProjectsSideBar from './projects-side-bar.jsx';
import ProjectsMainPanel from './projects-main-panel.jsx';
import MassingPanel from '../MassingPanel/massing-panel.jsx';

import './projects.scss';
import '../Pages/styles.scss';

class ProjectsPage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            projectCalculations: {},
            loading: true,
            selectedCalculationBKs: [],
            activateNewCalculation: false,
            clientBK: null,
            reloadMap: false,
            massingConfig: {
                isVisible: false,
                calculationBK: null,
                layoutBK: null
            },
            fanCalculationsInProgress: []
        };

        this.createNewCalculation = this.createNewCalculation.bind(this);
        this.fetchEntitledProjects = this.fetchEntitledProjects.bind(this);
        this.fetchProjectCalculations = this.fetchProjectCalculations.bind(this);
        this.renderLoader = this.renderLoader.bind(this);
        this.updateProjectsAndCalculations = this.updateProjectsAndCalculations.bind(this);
        this.triggerNewCalculation = this.triggerNewCalculation.bind(this);
        this.reloadMap = this.reloadMap.bind(this);
        this.toggleMassingModalVisibility = this.toggleMassingModalVisibility.bind(this);
        this.addFanCalculationsInProgress = this.addFanCalculationsInProgress.bind(this);
        this.removeFanCalculationsInProgress = this.removeFanCalculationsInProgress.bind(this);
    }

    addFanCalculationsInProgress(calculationBK) {
        this.setState({
            fanCalculationsInProgress: _.compact(_.union(this.state.fanCalculationsInProgress, [calculationBK]))
        });
    }

    removeFanCalculationsInProgress(calculationBK) {
        this.setState({
            fanCalculationsInProgress: _.without(this.state.fanCalculationsInProgress, calculationBK)
        });
    }

    async componentDidMount() {
        AuthService.checkAuthentication();
        if(!AuthService.isUserAuthenticated()) {
            return;
        }
        this.checkIE();
        this.checkRoleAccess();
        const projectBK = this.props.match.params.projectBK;

        if (this.props.location.projects) {
            this.setState({
                projects: this.props.location.projects,
                loading: false
            });
        } else {
            await this.fetchEntitledProjects();
        }
        if (_.isNil(this.state.projectCalculations[projectBK])) {
            await this.fetchProjectCalculations(projectBK);
        }

        let selectedCalculationBKs = this.getSelectedCalculationBKs();

        if (_.isEmpty(selectedCalculationBKs) && !_.isEmpty(this.state.projectCalculations[projectBK])) {
            selectedCalculationBKs = [this.state.projectCalculations[projectBK][0].businessKey];
        }
        await this.updateProjectsAndCalculations(projectBK, selectedCalculationBKs);
    }

    async componentDidUpdate() {
        AuthService.checkAuthentication();
        if(!AuthService.isUserAuthenticated()) {
            return;
        }
        const selectedCalculationBKs = this.getSelectedCalculationBKs();
        const isSelectedCalcsUpdated = !_.isEmpty(_.xor(this.state.selectedCalculationBKs, selectedCalculationBKs));
        if (isSelectedCalcsUpdated) {
            this.setState({
                selectedCalculationBKs
            });
        }

        if (_.isNil(this.state.clientBK)) {
            await this.fetchEntitledProjects();
        }
    }

    reloadMap(reloadMap) {
        this.setState({reloadMap});
    }

    getSelectedCalculationBKs() {
        const queryStrings = queryString.parse(this.props.location.search);
        let selectedCalculationBKs = [];
        if (!_.isNil(queryStrings.cbks)) {
            selectedCalculationBKs = queryStrings.cbks.split('_');
        }
        return selectedCalculationBKs;
    }

    checkRoleAccess() {
        !AuthService.hasMinimumReadRole() && this.props.history.push('/');
    }

    checkIE() {
        // Internet Explorer 6-11
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        isIE && NotificationManager.error('This page is not fully supported in Internet Explorer, please try in ' +
            'other browsers like Chrome, Firefox or Edge', 'Warning', 100000);
    }

    renderLoader(loading, timeoutOverride) {
        const loadingState = !!(loading);
        const timeout = (!_.isNil(timeoutOverride)) ? timeoutOverride : ((loading) ? 5 : 500);
        setTimeout(() => {
            if (loadingState !== this.state.loading) {
                this.setState({
                    loading: loadingState
                });
            }
        }, timeout);
    }

    toggleMassingModalVisibility() {
        this.setState({isMassingModalVisible: !this.state.isMassingModalVisible});
    }
    createNewCalculation(project, event, isFirstCalculation) {
        event.stopPropagation();

        if (project && project.projectType === 'LAYOUT') {
            const layoutBK = project.businessKey;
            const massingConfig = {
                calculationBK: null,
                layoutBK,
                accessToken: AuthService.getToken()
            };
            this.toggleMassingModalVisibility();
            this.setState({massingConfig});
        } else {
            this.triggerNewCalculation(true);
            this.setState({selectedCalculationBKs: []});
            this.checkRouteUpdate(project.businessKey, []);
            if (isFirstCalculation) {
                NotificationManager.info('Select a parcel on the map and enter the height for your Fan calculation.', '', 10000);
            }
        }
    }

    checkRouteUpdate(projectBK, calculationBKs) {
        const validCalculationBKs = _.map(this.state.projectCalculations[projectBK], 'businessKey');
        const legitCalculationBKs = _.intersection(calculationBKs, validCalculationBKs);

        const query = (!_.isEmpty(calculationBKs)) ? `?cbks=${legitCalculationBKs.join('_')}` : '';
        if (_.isNil(projectBK)) {
            projectBK = this.state.projects[0].businessKey;
        }
        this.props.history.push(`/projects/${projectBK}${query}`);
        return;
    }

    triggerNewCalculation(toggle) {
        this.setState({
            activateNewCalculation: toggle
        });
    }

    async fetchEntitledProjects() {
        let projects = [];
        let clientBK = '';
        const clientsReq = await window.FastcastService().getClients(AuthService.getToken());
        const clients = clientsReq.data || [];
        if (_.get(clients, ['0', 'metadata', 'layoutProject'])) {
            this.setState({layoutProject: true});
        }
        if (AuthService.isClientAdmin() || AuthService.isSuperAdmin()) {
            const reqProjects = await window.FastcastService().getClientProjects(
                AuthService.getToken(), clients[0].businessKey);
            clientBK = clients[0].businessKey;
            projects = reqProjects.data || [];
        } else {
            const projectsReq = await window.FastcastService().getUserProjects(AuthService.getToken());
            projects = projectsReq.data || [];
            if (projects.length > 0) {
                clientBK = projects[0].clientBusinessKey;
            }
        }
        projects = _.sortBy(projects, 'name');
        this.setState({
            loading: false,
            projects,
            clientBK
        });
    }

    async fetchProjectCalculations(projectBK, forceSync) {
        let projectCalculations = _.cloneDeep(this.state.projectCalculations);
        if (_.isNil(projectCalculations[projectBK]) || forceSync) {
            const fetchedProjectCalculations = await UtilityService.getProjectCalculations(projectBK);
            projectCalculations[projectBK] = _.sortBy(fetchedProjectCalculations, 'maxHeight');
            this.setState({projectCalculations});
        }
        return projectCalculations[projectBK];
    }

    isProjectLoaded(projectBK) {
        if (_.isEmpty(projectBK)) {
            return false;
        }
        const project = _.find(this.state.projects, {businessKey: projectBK});
        return !_.isEmpty(project);
    }

    async updateProjectsAndCalculations(
        projectBK, calculationBKs, forceProjectsReload, forceCalculationsReload, dontUpdateRoute, reloadMap
    ) {
        let reloadProjects = false || forceProjectsReload;
        let reloadProjectCalculations = false || forceCalculationsReload;
        let activeCalculationBKs = [];
        const project = _.filter(this.state.projects, {businessKey: projectBK});
        reloadProjects = reloadProjects || _.isEmpty(project);

        if (reloadProjects) {
            await this.fetchEntitledProjects();
        }

        reloadProjectCalculations = reloadProjectCalculations || _.isEmpty(this.state.projectCalculations[projectBK]) ||
            _.isNil(this.state.projectCalculations[projectBK]);

        if (_.isNil(projectBK) && !_.isEmpty(this.state.projects)) {
            projectBK = this.state.projects[0].businessKey;
        }
        if (reloadProjectCalculations) {
            await this.fetchProjectCalculations(projectBK, forceCalculationsReload);
        }
        activeCalculationBKs = _.map(this.state.projectCalculations[projectBK], 'businessKey');
        activeCalculationBKs = _.intersection(calculationBKs, activeCalculationBKs);

        if (!dontUpdateRoute) {
            this.setState({
                selectedCalculationBKs: _.compact(activeCalculationBKs),
                activateNewCalculation: false
            });
            this.checkRouteUpdate(projectBK, activeCalculationBKs);
        }

        if (reloadMap) {
            this.reloadMap(true);
        }
    }

    render() {
        return (
            <Fragment>
                <div className={'app-container tools-screens'}>
                    <Header active={PAGES.TOOLS}/>
                    <div className={'component-container'}>
                        <div className={'project-container'}>
                            <ProjectsSideBar
                                activeProjectBK = {this.props.match.params.projectBK}
                                createNewCalculation = {this.createNewCalculation}
                                projectCalculations = {this.state.projectCalculations}
                                projects = {this.state.projects}
                                renderLoader = {this.renderLoader}
                                clientBK = {this.state.clientBK}
                                selectedCalculationBKs = {this.state.selectedCalculationBKs}
                                updateProjectsAndCalculations = {this.updateProjectsAndCalculations}
                                layoutProject = {this.state.layoutProject}
                                removeFanCalculationsInProgress = {this.removeFanCalculationsInProgress}
                                fanCalculationBKsInProgress = {this.state.fanCalculationsInProgress}
                            />
                            <ProjectsMainPanel
                                activeProjectBK = {this.props.match.params.projectBK}
                                activateNewCalculation={this.state.activateNewCalculation}
                                projectCalculations = {this.state.projectCalculations}
                                projects = {this.state.projects}
                                reloadMap={this.state.reloadMap}
                                reloadMapCall={this.reloadMap}
                                renderLoader = {this.renderLoader}
                                selectedCalculationBKs = {this.state.selectedCalculationBKs}
                                updateProjectsAndCalculations = {this.updateProjectsAndCalculations}
                                triggerNewCalculation = {this.triggerNewCalculation}
                                addFanCalculationsInProgress = {this.addFanCalculationsInProgress}
                            />
                        </div>
                    </div>
                    <Footer active={PAGES.TOOLS}/>
                </div>
                { this.state.isMassingModalVisible && (
                    <MassingPanel accessToken={this.state.massingConfig.accessToken}
                                  layoutBK={this.state.massingConfig.layoutBK}
                                  calculationBK={this.state.massingConfig.calculationBK}
                                  isVisible={this.state.isMassingModalVisible}
                                  toggleMassingModalVisibility={this.toggleMassingModalVisibility}
                    />
                )}
                <Loader loading={this.state.loading}/>
            </Fragment>
        );
    }
}

export default withRouter(ProjectsPage);
