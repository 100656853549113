import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './admin-styles.scss';
import _ from 'lodash';

import AuthService from '../../services/auth-service.js';
import classNames from 'classnames';

class ClientAdd extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            details: '',
            address: '',
            website: '',
            metadata: ''
        };
        this.newClientInputChange = this.newClientInputChange.bind(this);
        this.submitNewClient = this.submitNewClient.bind(this);
    }

    newClientInputChange(event, type) {
        let val = '';
        if (!_.isNil(event.target.value)) {
            val = event.target.value;
        }
        switch(type) {
            case 'name':
                this.setState({name: val});
                break;
            case 'details':
                this.setState({details: val});
                break;
            case 'address':
                this.setState({address: val});
                break;
            case 'website':
                this.setState({website: val});
                break;
            case 'metadata':
                this.setState({metadata: val});
                break;
            default:
                break;
        }
    }
    resetFields() {
        this.setState({
            name: '',
            address: '',
            website: '',
            details: '',
            metadata: ''
        });
    }
    submitNewClient() {
        const {name, details, address, website, metadata} = this.state;
        if (!_.isEmpty(name)) {
            let metadataParsed = {};
            if (metadata) {
                metadataParsed = JSON.parse(metadata);
            }
            return window.FastcastService().postClient(
                AuthService.getToken(), name, details, address, website, metadataParsed
            ).then(async () => {
                this.resetFields();
                this.props.hideForm(true);
                await this.props.refreshClients();
            });
        }
    }

    render() {
        return (
            <div className={classNames({
                'new-item-container client': true,
                'hide': !this.props.display
            })}>
                <div className={'input-div'}>
                    <span className={'label'}>Name:</span>
                    <input type='text' className='form-control' placeholder={'Client name'}
                        onChange={(e) => {this.newClientInputChange(e, 'name');}} value={this.state.name}/>
                </div>
                <div className={'input-div'}>
                    <span className={'label'}>Details:</span>
                    <input type='text' className='form-control' placeholder={'Client details'}
                        onChange={(e) => {this.newClientInputChange(e, 'details');}}
                        value={this.state.details}/>
                </div>
                <div className={'input-div'}>
                    <span className={'label'}>Address:</span>
                    <input type='text' className='form-control' placeholder={'Client address'}
                        onChange={(e) => {this.newClientInputChange(e, 'address');}}
                        value={this.state.address}/>
                </div>
                <div className={'input-div'}>
                    <span className={'label'}>Metadata:</span>
                    <input type='text' className='form-control' placeholder={'Client metadata'}
                        onChange={(e) => {this.newClientInputChange(e, 'metadata');}}
                        value={this.state.metadata}/>
                </div>
                <div className={'input-div'}>
                    <span className={'label'}>Website:</span>
                    <input type='text' className='form-control' placeholder={'Client website'}
                        onChange={(e) => {this.newClientInputChange(e, 'website');}}
                        value={this.state.website}/>
                </div>
                <div className={'input-div'}>
                    <button type="button" className="btn btn-primary" onClick={this.submitNewClient}
                        disabled={!this.state.name}
                    >
                        Create a new client
                    </button>
                </div>
            </div>
        );
    }
}

ClientAdd.prototypes = {
    display: PropTypes.bool.isRequired,
    hideForm: PropTypes.func.isRequired,
    refreshClients: PropTypes.func.isRequired
};
export default ClientAdd;
