import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import _ from 'lodash';
import AuthService from '../../services/auth-service.js';

import Header from '../NavPanels/header.jsx';
import Footer from '../NavPanels/footer.jsx';
import AdminTopNav from '../admin/top-nav.jsx';
import ClientsList from '../admin/clients-list.jsx';
import ProjectsList from '../admin/projects-list.jsx';
import UsersList from '../admin/users-list.jsx';
import CalculationsList from '../admin/calculations-list.jsx';
import {ADMIN, PAGES} from '../../constants/app-constants.js';
import Loader from '../Loader/loader.jsx';

import './styles.scss';

class AdminTools extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            projects: [],
            clientBK: null,
            projectBK: null,
            deleteModalText: 'Are you sure?',
            deleteModalActive: true,
            loading: false
        };
        this.updateView = this.updateView.bind(this);
        this.fetchClients = this.fetchClients.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.refreshClientProjects = this.refreshClientProjects.bind(this);

        this.showLoader(true);
    }

    componentDidMount() {
        AuthService.checkAuthentication();
        if(!AuthService.isUserAuthenticated()) {
            return;
        }
        this.checkRoutes();
        this.checkRoutesClientId();
        this.checkRoutesProjectId();
    }

    componentDidUpdate(prevProps) {
        AuthService.checkAuthentication();
        if(!AuthService.isUserAuthenticated()) {
            return;
        }
        if (this.props.viewName !== prevProps.viewName) {
            this.checkRoutes();
        }

        if (this.props.match.params.clientBK !== prevProps.match.params.clientBK) {
            this.checkRoutesClientId();
        }

        if (this.props.match.params.projectBK !== prevProps.match.params.projectBK) {
            this.checkRoutesProjectId();
        }
    }

    checkRoutesClientId() {
        const clientBK = (this.props.match.params.clientBK) ? this.props.match.params.clientBK : null;
        this.refreshClientProjects(clientBK);
    }
    showLoader(isLoading) {
        isLoading = (isLoading) ? true : false;
        const timeout = (!isLoading) ? 500 : 0;
        setTimeout(() => {
            if (isLoading !== this.state.loading) {
                this.setState({loading: isLoading});
            }
        }, timeout);
    }
    refreshClientProjects(clientBK) {
        this.showLoader(true);
        let currState = {
            clientBK,
            projects: []
        };
        if (!_.isNil(clientBK)) {
            window.FastcastService().getClientProjects(AuthService.getToken(), clientBK).then((resp) => {
                currState.projects = (resp && resp.data) ? resp.data : [];
                this.setState(currState);
                this.showLoader(false);
            });
        } else {
            this.setState(currState);
            this.showLoader(false);
        }
    }

    checkRoutesProjectId() {
        const projectBK = (this.props.match.params.projectBK) ? this.props.match.params.projectBK : null;
        this.setState({projectBK});
    }

    checkRoutes() {
        const isAdmin = AuthService.isClientAdmin() || AuthService.isSuperAdmin();
        !isAdmin && this.props.history.push('/');
        switch(this.props.viewName) {
            case 'clients':
                this.setState({
                    render: ADMIN.CLIENTS
                });
                this.fetchClients();
                break;

            case 'projects':
                this.setState({
                    render: ADMIN.PROJECTS
                });
                break;
            case 'users':
                this.setState({
                    render: ADMIN.USERS
                });
                break;
            case 'calculations':
                this.setState({
                    render: ADMIN.CALCULATIONS
                });
                break;
            default:
                this.setState({
                    render: ''
                });
                break;
        }
    }

    updateView(viewName, event) {
        event.stopPropagation();
        this.props.history.push(`/admin/${viewName}`);
    }

    async fetchClients() {
        this.showLoader(true);
        const req = await window.FastcastService().getClients(AuthService.getToken());
        const clients = req.data || [];
        this.showLoader(false);
        this.setState({clients});
    }

    renderView() {
        switch (this.state.render) {
            case ADMIN.CLIENTS:
                return (<ClientsList clients={this.state.clients}
                    refreshClients={this.fetchClients}
                    showLoader={this.showLoader}
                />);
            case ADMIN.PROJECTS:
                return (<ProjectsList clients={this.state.clients}
                    projects={this.state.projects}
                    history={this.props.history}
                    clientBK={this.state.clientBK}
                    refreshClientProjects={this.refreshClientProjects}
                    showLoader={this.showLoader}
                />);
            case ADMIN.USERS:
                return (<UsersList clients={this.state.clients}
                    projects={this.state.projects}
                    clientBK={this.state.clientBK}
                    projectBK={this.state.projectBK}
                    history={this.props.history}
                    showLoader={this.showLoader}
                />);
            case ADMIN.CALCULATIONS:
                return (<CalculationsList
                    projectBK={this.state.projectBK}
                    showLoader={this.showLoader}
                />);
            default:
                return;
        }
    }

    render() {
        return (
            <Fragment>
                <div className={'app-container admin-screens'}>
                    <Header active={PAGES.ADMIN}/>
                    <div className={'component-container'}>
                        <AdminTopNav viewName={this.props.viewName || ''}/>
                        {this.renderView()}
                        <Loader loading={this.state.loading}/>
                    </div>
                    <Footer active={PAGES.ADMIN}/>
                </div>
            </Fragment>
        );
    }
}

AdminTools.prototypes = {
    viewName: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export default withRouter(AdminTools);
