import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import _ from 'lodash';
import classNames from 'classnames';
import CheckboxGroup from 'react-checkbox-group';

import './projects-side-bar.scss';


class ProjectsSideBarProject extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            disabledProjectMap: {}
        };
        this.projectHasCalculations = this.projectHasCalculations.bind(this);
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
        if ((_.isEmpty(prevProps.projects) && !_.isEmpty(this.props.projects) &&
            !_.isNil(this.props.activeProjectBK)) ||
            (!_.isEmpty(this.props.projects) && this.props.activeProjectBK !== prevProps.activeProjectBK)
        ) {
            this.props.projectExpand(this.props.activeProjectBK, true);
        }
        // if (prevProps.fanCalculationBKsInProgress !== this.props.fanCalculationBKsInProgress) {
        //     // this.forceUpdate();
        // }
    }
    projectHasCalculations(projectBK) {
        return !(_.isEmpty(this.props.projectCalculations[projectBK]) ||
            _.isNil(this.props.projectCalculations[projectBK]));
    }

    expandIcon() {
        return (<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path fill='none' d='M0 0h24v24H0V0z'/>
            <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'/>
        </svg>);
    }
    cancelIcon() {
        return (<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 8 8'>
            <path d='M1.41 0l-1.41 1.41.72.72 1.78 1.81-1.78 1.78-.72.69 1.41 1.44.72-.72 1.81-1.81 1.78 1.81.69.72 1.44-1.44-.72-.69-1.81-1.78 1.81-1.81.72-.72-1.44-1.41-.69.72-1.78 1.78-1.81-1.78-.72-.72z' />
        </svg>);
    }

    collapseIcon() {
        return (<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path fill='none' d='M0 0h24v24H0V0z'/>
            <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/>
        </svg>);
    }

    doArraysOverlap(array1, array2) {
        return !_.isEmpty(_.intersection(array1, array2));
    }

    render() {
        const relevantProjects = this.props.projects.filter(project => project.projectType === this.props.projectType);
        return (
            <Fragment>
                {relevantProjects.map(project => (
                    <div key={project.name} className={classNames({
                        'sidebar-element': true,
                        'active': this.props.activeProjectBK === project.businessKey
                    })}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.updateProjectsAndCalculations(project.businessKey, []);
                    }}>
                        <label className={'project-name'}  onClick={(e) => {
                            this.props.projectExpand(
                                project.businessKey,
                                !this.props.expandedProjectBKs.includes(project.businessKey), e
                            );
                        }}>
                            <span className={'indicator'}>
                                <span className={classNames({
                                    'collapsed': true,
                                    'hidden': this.props.expandedProjectBKs.includes(project.businessKey)
                                })}>
                                    {this.expandIcon()}
                                </span>
                                <span className={classNames({
                                    'expanded': true,
                                    'hidden': !this.props.expandedProjectBKs.includes(project.businessKey)
                                })}>
                                    {this.collapseIcon()}
                                </span>
                            </span>
                            {project.name}
                        </label>

                        <span className='close' onClick={(e) => {
                            this.props.showDeleteProjectConfirmation(project.businessKey, e);
                        }}>
                            {this.cancelIcon()}
                        </span>
                        {/* eslint-disable-next-line max-len */}
                        <CheckboxGroup name='calculations' value={this.props.selectedCalculationBKs}
                            onChange={
                                (activeCalculationBKs) => {
                                    this.props.handleCheckboxChange(activeCalculationBKs, project.businessKey);
                                }
                            }>
                            {(Checkbox) => (
                                <>
                                    { this.props.expandedProjectBKs.includes(project.businessKey) &&
                                        this.props.projectCalculations[project.businessKey] &&
                                    this.props.projectCalculations[project.businessKey].map(calculation => (
                                        <div key={calculation.businessKey}
                                            className={classNames({
                                                'sidebar-sub-element': true,
                                                'active': this.props.selectedCalculationBKs.includes(
                                                    calculation.businessKey
                                                )
                                            })}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <label className={'calculation-name'}>
                                                <Checkbox value={calculation.businessKey} />
                                                {/* eslint-disable-next-line max-len */}
                                                {calculation.name} {calculation.netNew.completed && ' - Net New'} {calculation.netNew.queued && '  - Net New Queued'} {calculation.netNew.error && '  - Net New Error'}
                                            </label>
                                            <span className='close' onClick={(e) => {
                                                this.props.deleteCalculation(project.businessKey, calculation, e);
                                            }}>
                                                {this.cancelIcon()}
                                            </span>
                                        </div>
                                    ))
                                    }
                                </>
                            )}
                        </CheckboxGroup>
                        <div className={
                            classNames({
                                'sidebar-sub-element add-link': true,
                                'show': this.props.activeProjectBK === project.businessKey ||
                                    this.props.expandedProjectBKs.includes(project.businessKey)
                            })
                        } onClick={(e) => {
                            !this.doArraysOverlap(this.props.fanCalculationBKsInProgress, _.map(this.props.projectCalculations[project.businessKey], 'businessKey')) && this.props.createNewCalculation(
                                project, e, !this.projectHasCalculations(project.businessKey));
                        }} disabled={true}>
                            {this.props.projectType === 'LAYOUT' &&
                                <label className={
                                    classNames({
                                        'new-calculation': true,
                                        'disabled': this.doArraysOverlap(this.props.fanCalculationBKsInProgress, _.map(this.props.projectCalculations[project.businessKey], 'businessKey'))
                                    })
                                }><strong>+</strong>  new scenario</label>
                            }
                            {this.props.projectType === 'PARCEL' &&
                                <label className={
                                    classNames({
                                        'new-calculation': true,
                                        'disabled': this.doArraysOverlap(this.props.fanCalculationBKsInProgress, _.map(this.props.projectCalculations[project.businessKey], 'businessKey'))
                                    })
                                }><strong>+</strong>  new calculation</label>
                            }
                        </div>
                    </div>
                ))}
            </Fragment>
        );
    }
}

ProjectsSideBarProject.propTypes = {
    fanCalculationBKsInProgress: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeProjectBK: PropTypes.string,
    createNewCalculation: PropTypes.func.isRequired,
    projectType: PropTypes.string.isRequired,
    projects: PropTypes.arrayOf(Object).isRequired,
    projectCalculations: PropTypes.object.isRequired,
    renderLoader: PropTypes.func.isRequired,
    selectedCalculationBKs: PropTypes.arrayOf(PropTypes.string),
    updateProjectsAndCalculations: PropTypes.func,
    deleteCalculation: PropTypes.func.isRequired,
    showDeleteProjectConfirmation: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    projectExpand: PropTypes.func.isRequired,
    expandedProjectBKs: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withRouter(ProjectsSideBarProject);
