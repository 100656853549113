import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import Pusher from 'pusher-js';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';

import AuthService from '../../services/auth-service.js';
import ConfirmationModal from '../Modals/confirmation-modal.jsx';
import ProjectsSideBarProject from './projects-side-bar-project.jsx';
import ProjectsSideBarNew from './projects-side-bar-new.jsx';

import './projects-side-bar.scss';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: 'mt1'
});

class ProjectsSideBar extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            newProjectActive: false,
            newProjectName: '',
            pusherChannel: null,
            deleteProjectActive: false,
            deleteProjectModalText: 'Are you sure?',
            deleteProjectBK: null,
            expandedProjectBKs: []
        };
        this.createProject = this.createProject.bind(this);
        this.deleteCalculation = this.deleteCalculation.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.hideDeleteProjectModal = this.hideDeleteProjectModal.bind(this);
        this.projectHasCalculations = this.projectHasCalculations.bind(this);
        this.projectExpand = this.projectExpand.bind(this);
        this.showDeleteProjectConfirmation = this.showDeleteProjectConfirmation.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if ((_.isEmpty(prevProps.projects) && !_.isEmpty(this.props.projects) &&
            !_.isNil(this.props.activeProjectBK)) || (!_.isEmpty(this.props.projects) &&
            this.props.activeProjectBK !== prevProps.activeProjectBK)
        ) {
            this.initializePusherListeners(this.props.clientBK, _.map(this.props.projects, 'businessKey'));
        }
        if (!_.isEmpty(this.props.projects) && !_.isNil(this.props.clientBK) && _.isNil(this.state.pusherChannel)) {
            this.initializePusherListeners(this.props.clientBK, _.map(this.props.projects, 'businessKey'));
        }
    }

    async projectExpand(projectBK, toExpand, toReloadMap) {
        if (_.isNil(this.props.projectCalculations[projectBK])) {
            this.props.renderLoader(true);
        }
        await this.props.updateProjectsAndCalculations(projectBK, null, false, true, true, toReloadMap === true);
        const expandedProjectBKs = (toExpand) ? _.union(this.state.expandedProjectBKs, [projectBK]) :
            _.without(this.state.expandedProjectBKs, projectBK);

        if (!_.isEmpty(_.xor(expandedProjectBKs, this.state.expandedProjectBKs))) {
            this.setState({expandedProjectBKs});
        }
        this.props.renderLoader(false);
    }

    initializePusherListeners(clientBusinessKey, projectBusinessKeys) {
        if (_.isNil(clientBusinessKey) || _.isEmpty(projectBusinessKeys)) {
            return;
        }
        let pusherChannel = this.state.pusherChannel;
        if (!pusherChannel) {
            pusherChannel = pusher.subscribe(clientBusinessKey);
            this.setState({pusherChannel});
        }

        pusherChannel.unbind();
        projectBusinessKeys.forEach((projectBK) => {
            pusherChannel.bind(projectBK, async (data) => {
                // data includes keys calculationKey, message, status (started, completed or errored)
                const project = _.find(this.props.projects, {businessKey: projectBK});
                if (data.status === 'errored') {
                    if (data.calculationType === 'FAN') {
                        this.props.removeFanCalculationsInProgress(data.calculationKey);
                        NotificationManager.error(
                            `Your Fan calculation for ${project.name} has failed to execute. Please try again or contact support.`, '', 10000);
                    } else if (data.calculationType === 'NET-NEW') {
                        NotificationManager.error(
                            `Your Net New calculation for ${project.name} has failed to execute. Please try again or contact support.`, '', 10000);
                    }
                } else if (data.status === 'started') {
                    if (data.calculationType === 'FAN') {
                        NotificationManager.success(`Your Fan calculation for ${project.name} has started. You will be notified upon completion.`, '', 7000);
                    } else if (data.calculationType === 'NET-NEW') {
                        NotificationManager.success(`Your Net New calculation for ${project.name} has started. You will be notified upon completion.`, '', 10000);
                    }

                } else if (data.status === 'completed') {
                    if (data.calculationType === 'FAN') {
                        this.props.removeFanCalculationsInProgress(data.calculationKey);
                        // this.props.updateProjectsAndCalculations(projectBK, [data.calculationKey], false, true);

                        NotificationManager.success(`Your Fan calculation for ${project.name} has completed.`, '', 7000);
                    } else if (data.calculationType === 'NET-NEW') {
                        // await this.projectExpand(projectBK, true, true);
                        NotificationManager.success(`Your Net New calculation for ${project.name} has completed.`, '', 10000);
                    }
                    const toReloadMapDisplay = this.props.activeProjectBK === projectBK &&
                        this.props.selectedCalculationBKs.includes(data.calculationKey);
                    await this.projectExpand(projectBK, true, toReloadMapDisplay);
                }
            });
        });
    }

    hideDeleteProjectModal() {
        this.setState({
            deleteProjectActive: false,
            deleteProjectBK: null
        });
    }

    async showDeleteProjectConfirmation(projectBK, event) {
        event.stopPropagation();
        if (!_.isEmpty(this.props.projectCalculations[projectBK])) {
            NotificationManager.error(
                'Please remove calculations and users under this project before deleting.', '', 3000);
        } else {
            await this.setState({
                deleteProjectActive: true,
                deleteProjectBK: projectBK
            });
        }
    }

    projectHasCalculations(projectBK) {
        return !(_.isEmpty(this.props.projectCalculations[projectBK]) ||
            _.isNil(this.props.projectCalculations[projectBK]));
    }

    async createProject(newProjectName, projectType, event) {
        event.stopPropagation();
        if (!_.isEmpty(newProjectName)) {
            this.props.renderLoader(true);
            try {
                const clientsReq = await window.FastcastService().getClients(AuthService.getToken());
                const clients = clientsReq.data || [];

                if (_.isEmpty(clients)) {
                    NotificationManager.error(
                        'Unfortunately, you need access to a client to create project. Please contact support.',
                        '',
                        8000
                    );
                } else {
                    const resp = await window.FastcastService().postProject(
                        AuthService.getToken(), clients[0].businessKey, newProjectName, projectType);
                    if (_.isEmpty(resp.data)) {
                        throw new Error('Error: failed to create project');
                    }
                    const projectBK = resp.data.businessKey;
                    await this.props.updateProjectsAndCalculations(projectBK, []);
                }
            } catch (error) {
                NotificationManager.error(
                    'Failed to create a new project.',
                    '',
                    8000
                );
            }
        }
    }

    deleteCalculation(projectBK, calculation, event) {
        this.props.renderLoader(true);
        event.stopPropagation();
        const dontUpdateRoute = this.props.activeProjectBK !== projectBK;

        return window.FastcastService().deleteCalculation(AuthService.getToken(), calculation.businessKey)
            .catch(() => {
                NotificationManager.error(
                    'Please check if you have permission to delete this calculation.', '', 8000);
            }).finally(async () => {
                this.props.renderLoader(false);
                await this.props.updateProjectsAndCalculations(
                    // eslint-disable-next-line max-len
                    projectBK, _.without(this.props.selectedCalculationBKs, calculation.businessKey), false, true, dontUpdateRoute
                );
            });
    }

    async deleteProject() {
        const projectBK = this.state.deleteProjectBK;
        if (_.isEmpty(projectBK)) {
            return;
        }

        this.props.renderLoader(true);
        let errorCaught = false;
        try {
            await window.FastcastService().deleteProjectUser(
                AuthService.getToken(), projectBK, AuthService.getUserId()
            );
            await window.FastcastService().deleteProject(AuthService.getToken(), projectBK);
        } catch (error) {
            errorCaught = true;
            NotificationManager.error(
                'Please remove calculations and users under this project before deleting it.', '', 8000);
        } finally {
            this.hideDeleteProjectModal();
            if (!errorCaught) {
                await this.props.updateProjectsAndCalculations(null, null, true, true);
            }
            this.props.renderLoader(false);
        }
    }

    handleCheckboxChange(calculationBKs, projectBk) {
        const calBKs = !_.isEmpty(calculationBKs) ? calculationBKs : [];
        this.props.updateProjectsAndCalculations(projectBk, calBKs);
    }

    render() {
        return (
            <Fragment>
                <div className='sidebar-container'>
                    <div className='projects-header'>
                        Projects
                    </div>
                    <ProjectsSideBarProject
                        activeProjectBK={this.props.activeProjectBK}
                        createNewCalculation={this.props.createNewCalculation}
                        projectType={'PARCEL'}
                        projects={this.props.projects}
                        projectCalculations={this.props.projectCalculations}
                        renderLoader={this.props.renderLoader}
                        selectedCalculationBKs={this.props.selectedCalculationBKs}
                        updateProjectsAndCalculations = {this.props.updateProjectsAndCalculations}
                        deleteCalculation={this.deleteCalculation}
                        showDeleteProjectConfirmation={this.showDeleteProjectConfirmation}
                        handleCheckboxChange={this.handleCheckboxChange}
                        projectExpand={this.projectExpand}
                        expandedProjectBKs={this.state.expandedProjectBKs}
                        fanCalculationBKsInProgress={this.props.fanCalculationBKsInProgress}
                    />
                    <ProjectsSideBarNew
                        projectType={'PARCEL'}
                        createProject={this.createProject}
                    />
                    {this.props.layoutProject &&
                    <Fragment>
                        <ProjectsSideBarProject
                            activeProjectBK={this.props.activeProjectBK}
                            createNewCalculation={this.props.createNewCalculation}
                            projectType={'LAYOUT'}
                            projects={this.props.projects}
                            projectCalculations={this.props.projectCalculations}
                            renderLoader={this.props.renderLoader}
                            selectedCalculationBKs={this.props.selectedCalculationBKs}
                            updateProjectsAndCalculations = {this.props.updateProjectsAndCalculations}
                            deleteCalculation={this.deleteCalculation}
                            showDeleteProjectConfirmation={this.showDeleteProjectConfirmation}
                            handleCheckboxChange={this.handleCheckboxChange}
                            projectExpand={this.projectExpand}
                            expandedProjectBKs={this.state.expandedProjectBKs}
                            fanCalculationBKsInProgress={this.props.fanCalculationBKsInProgress}
                        />
                        <ProjectsSideBarNew
                            projectType={'LAYOUT'}
                            createProject={this.createProject}
                        />
                        <div className={'vertical-divider-20'}/>
                    </Fragment>
                    }
                </div>

                <ConfirmationModal
                    isVisible={this.state.deleteProjectActive}
                    modalText={this.state.deleteProjectModalText}
                    yesHandler={this.deleteProject}
                    noHandler={this.hideDeleteProjectModal}
                />
            </Fragment>
        );
    }
}

ProjectsSideBar.propTypes = {
    activeProjectBK: PropTypes.string,
    clientBK: PropTypes.string,
    createNewCalculation: PropTypes.func.isRequired,
    layoutProject: PropTypes.bool,
    projectCalculations: PropTypes.object.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    renderLoader: PropTypes.func.isRequired,
    selectedCalculationBKs: PropTypes.arrayOf(PropTypes.string),
    updateProjectsAndCalculations: PropTypes.func,
    removeFanCalculationsInProgress: PropTypes.func.isRequired,
    fanCalculationBKsInProgress: PropTypes.arrayOf(PropTypes.string)
};

export default withRouter(ProjectsSideBar);
