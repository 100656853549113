import React, { PureComponent, Fragment } from 'react';

import AuthService from '../../services/auth-service.js';
import Header from '../NavPanels/header.jsx';
import Footer from '../NavPanels/footer.jsx';
import {PAGES} from '../../constants/app-constants.js';

import './home.scss';

class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: '/images/hero1024.jpg'
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidUpdate() {
        this.gotoHome();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentDidMount() {
        this.gotoHome();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        let imageWidth = 1024;
        if (window.innerWidth >= 1024) {
            imageWidth = 1600;
        } else if (window.innerWidth > 800 && window.innerWidth <= 1024) {
            imageWidth = 1600;
        } else if (window.innerWidth > 500 && window.innerWidth <= 800) {
            imageWidth = 800;
        } else if (window.innerWidth > 0 && window.innerWidth <= 500) {
            imageWidth = 500;
        }
        this.setState({
            imageUrl: `/images/hero${imageWidth}.jpg`
        });
    }
    gotoHome() {
        if(!window.location.pathname.includes(PAGES.HOME)) {
            this.props.history.push(`/${PAGES.HOME}`);
        }
    }

    gotoSignUp() {
        window.location = AuthService.getSignUpUrl();
    }

    render() {
        return (
            <Fragment>
                <Header active={PAGES.HOME}/>
                <div className='home'>
                    <div className='leader leader--home'>
                        <div className='leader__image'>
                            <img src={this.state.imageUrl} width={'100%'} alt=''/>
                        </div>
                        <div className='leader__panel'>
                            <div className='leader__panel__row leader__panel__row--white col-md col-sm order-md-1'>
                                <div className='global--width'>
                                    <div className='row-top'>
                                        <p>
                                            Fastcast is leading the way in data-driven environmental design and
                                            analysis, and setting new standards for access to urban planning
                                            tools and information.
                                        </p>
                                        <br/>
                                        {!AuthService.isUserAuthenticated() && (
                                            <Fragment>
                                                <p>
                                                    Sign up today for free access to the San Francisco open
                                                    space baselines.
                                                </p>
                                                <div className='btn btn-warning btn-lg sign-up-button'
                                                    onClick={this.gotoSignUp}
                                                >
                                                    Sign Up
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container container--home global--width'>
                        <div className='row'>
                            <div className='col-md leader__image'>
                                <img src='/images/home--data.png' alt=''/>
                            </div>
                            <div className='col-md col-sm order-md-1'>
                                <h3>Fastcast Data</h3>
                                <p>
                                    Fastcast&rsquo;s San Francisco dataset produces the most precise and actionable
                                    simulation results available. Built upon existing conditions photogrammetry and our
                                    proprietary access to approved development projects, the Fastcast dataset
                                    ensures our clients have access to comprehensive, detailed and up-to-date data they
                                    can trust.
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md leader__image order-md-2'>
                                <img src='/images/home--tools.png' alt=''/>
                            </div>
                            <div className='col-md col-sm order-md-1'>
                                <h3>Solar Toolbox</h3>
                                <p>
                                    The Fastcast Solar Toolbox is a cloud-based solution that provides
                                    subscription-holders with the ability to leverage the core Fastcast data and
                                    technology. The Toolbox workflow gives clients quick views of potential
                                    impacts and detailed analysis of project impact and envelopes. The result is
                                    unprecedented access and turnaround times for all project stakeholders.
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md leader__image'>
                                <img src='/images/home--services.png' alt=''/>
                            </div>
                            <div className='col-md col-sm'>
                                <h3>Analysis Services</h3>
                                <p>
                                    Fastcast&rsquo;s services are the most trusted source of analysis for San
                                    Francisco&rsquo;s landmark open space “Sunlight Ordinance”. Fastcast has been
                                    delivering data, graphics, and reports into the ordinance for over 10 years and has
                                    been instrumental in assuring fair and timely resolution of all potential open space
                                    impact through every stage of the approval process.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer active={PAGES.HOME}/>
            </Fragment>
        );
    }
}

export default Home;
