import React, { PureComponent, Fragment } from 'react';
import {withRouter} from 'react-router';
import AuthService from '../../services/auth-service.js';

import Header from '../NavPanels/header.jsx';
import Footer from '../NavPanels/footer.jsx';
import Loader from '../Loader/loader.jsx';

import './styles.scss';
import {PAGES} from '../../constants/app-constants.js';

class SFParks extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidUpdate() {
        AuthService.checkAuthentication();
    }

    componentDidMount() {
        AuthService.checkAuthentication();
    }

    render() {
        return (
            <Fragment>
                <div className={'app-container'}>
                    <Header active={PAGES.SF_PARKS}/>
                    <div className={'component-container'}>
                        <Loader loading={this.state.loading}/>
                        <iframe id='sfparks-iframe' title='sf-parks'
                            src="https://sfparks.fastcastcity.net" className="iframe-style"/>
                    </div>
                    <Footer active={PAGES.SF_PARKS}/>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(SFParks);
