import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {NotificationManager} from 'react-notifications';

import AuthService from '../../services/auth-service.js';

import _ from 'lodash';
import './admin-styles.scss';
import {withRouter} from 'react-router';
import ClientAdd from './client-add.jsx';
import ConfirmationModal from '../Modals/confirmation-modal.jsx';

class ClientsList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isNewClientFormVisible: false,

            deleteModalActive: false,
            deleteModalText: 'Are you sure?',
            deleteClientBK: null
        };

        this.deleteClient = this.deleteClient.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.gotoProjects = this.gotoProjects.bind(this);
        this.gotoUsers = this.gotoUsers.bind(this);
        this.toggleNewClientForm = this.toggleNewClientForm.bind(this);
        this.toggleLayoutProject = this.toggleLayoutProject.bind(this);
    }

    gotoProjects(clientBK) {
        this.props.history.push(`/admin/clients/${clientBK}/projects`);
    }

    gotoUsers(clientBK) {
        this.props.history.push(`/admin/clients/${clientBK}/users`);
    }

    toggleNewClientForm(hide) {
        const isNewClientFormVisible = hide !== true;
        this.setState({isNewClientFormVisible});
    }

    deleteClient() {
        if (!_.isEmpty(this.state.deleteClientBK)) {
            return window.FastcastService().deleteClient(
                AuthService.getToken(), this.state.deleteClientBK).then(async () => {
                await this.props.refreshClients();
            }).catch(() => {
                NotificationManager.error(
                    'Please remove projects and users under this client before deleting it.', '', 8000);
            }).finally(() => {
                this.setState({
                    deleteModalActive: false
                });
            });
        }
    }

    hideDeleteModal(event, show, deleteClientBK) {
        this.setState({
            deleteModalActive: (show) ? true : false,
            deleteClientBK
        });
    }

    toggleLayoutProject(event, client) {
        if (_.isNil(client.metadata)) {
            client.metadata = {
                layoutProject: true
            };
        } else {
            client.metadata.layoutProject = !client.metadata.layoutProject;
        }
        return window.FastcastService().updateClient(
            AuthService.getToken(), client).then(async () => {
            await this.props.refreshClients();
        }).catch(() => {
            NotificationManager.error(
                'Failed to update the client.', '', 8000);
        });
    }

    render() {
        return (
            <Fragment>
                <ConfirmationModal
                    isVisible={this.state.deleteModalActive}
                    modalText={this.state.deleteModalText}
                    yesHandler={this.deleteClient}
                    noHandler={this.hideDeleteModal}
                />
                <div className={classNames({
                    'new-item-container': true,
                    'hide': !AuthService.isSuperAdmin()
                })}>
                    <button type="button"
                        className={classNames({
                            'btn btn-primary': true,
                            'hide': this.state.isNewClientFormVisible
                        })}
                        onClick={this.toggleNewClientForm}>New Client</button>
                    <ClientAdd display={this.state.isNewClientFormVisible}
                        refreshClients={this.props.refreshClients}
                        hideForm={this.toggleNewClientForm}
                    />
                </div>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th scope='col'>Business Key</th>
                            <th scope='col'>Name</th>
                            <th scope='col'>Address</th>
                            <th scope='col'>Active</th>
                            <th scope='col'>Metadata</th>
                            <th scope='col'>Go to</th>
                            {AuthService.isSuperAdmin() && (
                                <th scope='col'>Delete</th>
                            )}
                            {AuthService.isSuperAdmin() && (
                                <th scope='col'>Layout Project</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.clients.map((client) => {
                            return (
                                <tr key={client.businessKey}>
                                    <td>{client.businessKey}</td>
                                    <td>{client.name}</td>
                                    <td>{client.address}</td>
                                    <td>
                                        <input className='form-check-input'
                                            type='checkbox' value={client.active}
                                            id={client.businessKey}
                                            defaultChecked={client.active}
                                        />
                                    </td>
                                    <td>{(client.metadata) ? JSON.stringify(client.metadata) : ''}</td>
                                    <td>
                                        <button className='btn btn-primary'
                                            onClick={() => {this.gotoProjects(client.businessKey);}}>Project</button>
                                        <button className='btn btn-primary margin-left-20'
                                            onClick={() => {this.gotoUsers(client.businessKey);}}>Users</button>
                                    </td>
                                    {AuthService.isSuperAdmin() && (
                                        <td>
                                            <button className='btn btn-danger'
                                                onClick={(e)=> {this.hideDeleteModal(
                                                    e, true, client.businessKey);
                                                }}>
                                                DELETE
                                            </button>
                                        </td>
                                    )}
                                    {AuthService.isSuperAdmin() && (
                                        <td>
                                            <button className='btn btn-danger'
                                                onClick={(e)=> {this.toggleLayoutProject(
                                                    e, client);
                                                }}>
                                                TOGGLE
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Fragment>
        );
    }

}

ClientsList.prototypes = {
    clients: PropTypes.array.isRequired,
    refreshClients: PropTypes.func.isRequired
};
export default withRouter(ClientsList);
