import {esriService} from '@fastcast/lib';
import authService from './auth-service';
import _ from 'lodash';

class UtilityService {
    /**
     *
     * @param json
     * @returns {any}
     */
    parseJSON(json) {
        try {
            return JSON.parse(json);
        } catch(e) {
            return JSON.parse('{}');
        }
    }

    /**
     *
     * @param projectBusinessKey
     * @param calculationName
     * @param calculationMaxHeight
     * @param calculationMetadata
     * @returns {Promise<>}
     */
    computeNewFan(
        projectBusinessKey,
        clientBusinessKey,
        calculationMaxHeight,
        calculationMetadata
    ) {
        let calculation;
        calculationMaxHeight = parseInt(calculationMaxHeight);

        return window.FastcastService().postFanCalculation(
            authService.getToken(),
            projectBusinessKey,
            `${calculationMaxHeight} ft`,
            calculationMaxHeight,
            JSON.stringify(calculationMetadata)
        ).then((resp) => {
            if (resp && resp.data && resp.data.businessKey) {
                calculation = resp.data;
                return esriService.getParcelDxf(calculationMetadata.centroidHeight);
            }
            throw new Error('Failed to POST calculation');
        }).then((parcelDxf) => {
            if (parcelDxf) {
                return window.FastcastService().postParcelDxf(
                    authService.getToken(),
                    calculation.businessKey,
                    parcelDxf
                );
            }
            throw new Error('Failed to get parcel dxf');
        }).then((resp) => {
            if (resp) {
                return window.FastcastService().queueFanCalculation(
                    authService.getToken(),
                    calculation.businessKey,
                    projectBusinessKey,
                    clientBusinessKey,
                    calculationMaxHeight
                );
            }
            throw new Error('Failed to POST parcel dxf');
        }).then(() => {
            return calculation;
        });
    }

    /**
     *
     * @param projects
     * @returns {Promise<[]>}
     */
    getCalculationDxfs(projects) {
        let calculationBks = [];
        projects.forEach( project => {
            project.calculations.forEach( calculation => {
                if (calculation.businessKey && calculation.hasComputedCalculation) {
                    calculationBks.push(calculation.businessKey);
                }
            });
        });
        let calculationDxfPromises = [];
        calculationBks.forEach(cbk => {
            calculationDxfPromises.push(
                window.FastcastService().getCalculationDxf(authService.getToken(), cbk)
            );
        });
        let calculationDxfmap = {};
        return Promise.all(calculationDxfPromises).then(resp => {
            resp.forEach((calcDxf, i) => {
                calculationDxfmap[calculationBks[i]] = calcDxf.data;
            });
        }).then(() => {
            let updatedProjects = _.cloneDeep(projects);
            updatedProjects.forEach( project => {
                project.calculations.forEach( calculation => {
                    if (calculation.businessKey && calculation.hasComputedCalculation) {
                        calculation.dxf = calculationDxfmap[calculation.businessKey];
                        if (calculation.dxf) {
                            calculation.agolJson = esriService.convertDxfToAgolJson(calculation.dxf, '');
                        }
                    }
                });
                project.calculations = _.sortBy(project.calculations, ['name']);
            });
            return updatedProjects;
        }).catch(() => {
            return _.cloneDeep(projects);
        });
    }

    async getProjectCalculations(projectBK) {
        const calculations = await window.FastcastService().getCalculations(authService.getToken(), projectBK);
        let calculationBks = [];
        calculations.data.forEach(calc => {
            if (!_.isEmpty(calc.netNew)) {
                calc.netNew = JSON.parse(calc.netNew);
                calc.netNew.completed = calc.netNew.status === 'COMPLETED';
                calc.netNew.queued = calc.netNew.status === 'QUEUED';
                calc.netNew.notStarted = calc.netNew.status === 'NOT_FOUND';
                calc.netNew.error = calc.netNew.status === 'ERROR';
            }
            if (calc.hasComputedCalculation) {
                calculationBks.push(calc.businessKey);
            }
        });

        let calculationDxfPromises = [];
        calculationBks.forEach(cbk => {
            calculationDxfPromises.push(
                window.FastcastService().getCalculationDxf(authService.getToken(), cbk)
            );
        });
        let calculationDxfmap = {};
        return Promise.all(calculationDxfPromises).then(resp => {
            resp.forEach((calcDxf, i) => {
                calculationDxfmap[calculationBks[i]] = calcDxf.data;
            });
        }).then(() => {
            calculations.data.forEach( calculation => {
                if (calculation.hasComputedCalculation) {
                    calculation.dxf = calculationDxfmap[calculation.businessKey];
                    if (calculation.dxf) {
                        calculation.agolJson = esriService.convertDxfToAgolJson(calculation.dxf, '');
                    }
                }
            });
            return calculations.data;
        }).catch(() => {
            // return _.cloneDeep(projects);
        });
    }


    getEntitledProjectsWithCalculations(reqProjects) {
        let projects = [];
        return Promise.resolve().then(() => {
            if (reqProjects) {
                return Promise.resolve(reqProjects);
            } else {
                return window.FastcastService().getUserProjects(authService.getToken());
            }
        }).then((resp) => {
            projects = resp && resp.data ? resp.data : [];

            let calculationPromises = [];
            projects.forEach(project => {
                calculationPromises.push(
                    window.FastcastService().getCalculations(authService.getToken(), project.businessKey)
                );
            });
            return Promise.all(calculationPromises).then(responses => {
                responses.forEach((response, index) => {
                    response.data.forEach(calc => {
                        if (!_.isEmpty(calc.netNew)) {
                            calc.netNew = JSON.parse(calc.netNew);
                            calc.netNew.completed = calc.netNew.status === 'COMPLETED';
                            calc.netNew.queued = calc.netNew.status === 'QUEUED';
                            calc.netNew.notStarted = calc.netNew.status === 'NOT_FOUND';
                        }
                    });
                    projects[index].calculations = response.data;
                });
            }).then(() => {
                return this.getCalculationDxfs(projects);
            }).catch((error) => {
                console.error('Error occurred while retrieving Projects and calculations - ', error);
            });
        });
    }
}

export default new UtilityService();
