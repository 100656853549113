import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { PropagateLoader } from 'react-spinners';
import { css } from '@emotion/core';
import classNames from 'classnames';

import './loader.scss';

class Loader extends PureComponent {
    render() {
        const override = css`
            margin: auto;
        `;
        return (
            <div className={classNames({
                'load-spinner': true,
                'hide': !this.props.loading
            })}>
                <PropagateLoader
                    css={override}
                    sizeUnit={'px'}
                    size={25}
                    margin={'auto'}
                    color={'orange'}
                    loading={this.props.loading}
                />
            </div>
        );
    }
}

Loader.prototypes = {
    loading: PropTypes.bool.isRequired
};

export default Loader;
