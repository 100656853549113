import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import ReactGA from 'react-ga';

import ProjectsRoot from '../components/Projects/index';
import ProjectsPage from '../components/Projects/project';

import Contact from '../components/Pages/contact';
import Home from '../components/Pages/home';
import Tools from '../components/Pages/tools';
import AdminTools from '../components/Pages/admin';
import SFParks from '../components/Pages/sf-parks';
import {FC_PATH} from '../constants/app-constants';

const history = createBrowserHistory();
history.listen(location => {
    const host = window.location.host;
    if (host.includes(FC_PATH.PROD) || host.includes(FC_PATH.DEV)) {
        ReactGA.set({page: location.pathname});
        ReactGA.pageview(location.pathname);
    }
});

const routesArray = [
    {
        path: '/',
        view: 'home',
        component: Home
    }, {
        path: '/contact',
        view: 'contact',
        component: Contact
    }, {
        path: '/parks',
        view: 'sf-parks',
        component: SFParks
    }, {
        path: '/projects',
        view: 'projectsRoot',
        component: ProjectsRoot
    }, {
        path: '/projects/:projectBK',
        view: 'projectsPage',
        component: ProjectsPage
    }, {
        path: '/tools',
        view: 'tools',
        component: Tools
    }, {
        path: '/admin',
        view: 'admin',
        viewName: 'clients',
        component: AdminTools
    }, {
        path: '/admin/clients',
        view: 'admin',
        viewName: 'clients',
        component: AdminTools
    }, {
        path: '/admin/clients/:clientBK',
        view: 'admin',
        viewName: 'clients',
        component: AdminTools
    }, {
        path: '/admin/clients/:clientBK/projects/:projectBK',
        view: 'admin',
        viewName: 'projects',
        component: AdminTools
    }, {
        path: '/admin/clients/:clientBK/projects',
        view: 'admin',
        viewName: 'projects',
        component: AdminTools
    }, {
        path: '/admin/clients/:clientBK/users',
        view: 'admin',
        viewName: 'users',
        component: AdminTools
    }, {
        path: '/admin/clients/:clientBK/projects/:projectBK/users',
        view: 'admin',
        viewName: 'users',
        component: AdminTools
    }, {
        path: '/admin/clients/:clientBK/projects/:projectBK/calculations',
        view: 'admin',
        viewName: 'calculations',
        component: AdminTools
    }, {
        path: '/admin/projects',
        view: 'admin',
        viewName: 'projects',
        component: AdminTools
    }
];

const Routes = () => {
    return (
        <Router history={history}>
            <Switch>
                {routesArray.map((route, index) => (
                    <Route exact
                        key={index}
                        path={route.path}
                        render={(props) => <route.component {...props} viewName={route.viewName}/>}
                    />
                ))}
            </Switch>
        </Router>
    );
};

export default Routes;
