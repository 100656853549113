import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {NotificationManager} from 'react-notifications';

import AuthService from '../../services/auth-service.js';
import ClientSelector from './client-selector.jsx';
import './admin-styles.scss';
import UserAdd from './user-add.jsx';
import UserProjectAdd from './user-project-add.jsx';
import ConfirmationModal from '../Modals/confirmation-modal.jsx';

class UsersList extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            clientUsers: [],
            projectUsers: [],

            deleteModalActive: false,
            deleteModalText: 'Are you sure?',
            deleteUserBK: null,
            deleteProjectBK: null
        };
        this.updateClient = this.updateClient.bind(this);
        this.refreshHandler = this.refreshHandler.bind(this);
        this.projectDropdownChange = this.projectDropdownChange.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
    }

    componentDidMount() {
        this.refreshClientUsers();
        this.refreshProjectUsers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.clientBK !== this.props.clientBK) {
            this.refreshClientUsers();
        }

        if (prevProps.projectBK !== this.props.projectBK) {
            this.refreshProjectUsers();
        }
    }

    deleteUser() {
        if (!_.isEmpty(this.state.deleteUserBK) && !_.isEmpty(this.state.deleteProjectBK)) {
            this.props.showLoader(true);
            return window.FastcastService().deleteProjectUser(
                AuthService.getToken(), this.state.deleteProjectBK, this.state.deleteUserBK).then(async () => {
                this.setState({
                    deleteModalActive: false
                });
                this.refreshProjectUsers();
                this.props.showLoader(false);
            });
        } else if (!_.isEmpty(this.state.deleteUserBK)) {
            this.props.showLoader(true);
            return window.FastcastService().deleteClientUser(
                AuthService.getToken(), this.state.deleteUserBK).then(async () => {
                this.refreshClientUsers();
            }).catch(() => {
                NotificationManager.error(
                    'Please remove this user from projects before deleting from this client.', '', 8000);
            }).finally(() => {
                this.setState({
                    deleteModalActive: false
                });
                this.props.showLoader(false);
            });
        }
    }

    hideDeleteModal(event, show, deleteUserBK, deleteProjectBK) {
        this.setState({
            deleteModalActive: (show) ? true : false,
            deleteUserBK,
            deleteProjectBK
        });
    }

    refreshClientUsers() {
        if (!_.isEmpty(this.props.clientBK)) {
            this.props.showLoader(true);
            window.FastcastService().getClientUsers(AuthService.getToken(), this.props.clientBK).then((resp) => {
                const clientUsers = resp && resp.data ? resp.data : [];
                this.setState({clientUsers});
                this.props.showLoader(false);
            });
        }
    }

    refreshProjectUsers() {
        if (!_.isEmpty(this.props.projectBK)) {
            this.props.showLoader(true);
            window.FastcastService().getProjectUsers(AuthService.getToken(), this.props.projectBK).then((resp) => {
                const projectUsers = resp && resp.data ? resp.data : [];
                this.setState({projectUsers});
                this.props.showLoader(false);
            });
        }
    }

    updateClient(resp) {
        if (resp.data && resp.data.businessKey) {
            this.props.history.push(
                `/admin/clients/${resp.data.businessKey}/users`
            );
        }
    }

    refreshHandler() {
        this.refreshClientUsers();
        this.refreshProjectUsers();
    }

    projectDropdownChange(event) {
        let val = '';
        if (event.target.value && event.target.value !== 'default') {
            val = event.target.value;
            this.props.history.push(
                `/admin/clients/${this.props.clientBK}/projects/${val}/users`
            );
        }
    }

    render() {
        return (
            <Fragment>
                <ConfirmationModal
                    isVisible={this.state.deleteModalActive}
                    modalText={this.state.deleteModalText}
                    yesHandler={this.deleteUser}
                    noHandler={this.hideDeleteModal}
                />
                <ClientSelector clientBK={this.props.clientBK} newClientHandler={this.updateClient}/>
                <div className={'table-header'}>
                    Project Users:
                    <div className='input-group-prepend'>
                        <select className='custom-select' id='inputGroupSelect01'
                            onChange={this.projectDropdownChange} value={this.props.projectBK || 'default'}>
                            <option defaultValue='default'>Project Business Key</option>
                            {_.map(this.props.projects, 'businessKey').map((projectBK) => {
                                return (<option value={projectBK} key={projectBK}>{projectBK}</option>);
                            })}
                        </select>
                    </div>
                </div>
                <UserProjectAdd clientBK={this.props.clientBK} userType={'project'}
                    projectBKs={_.map(this.props.projects, 'businessKey')}
                    users={this.state.clientUsers}
                    showLoader={this.props.showLoader}
                    refreshHandler={this.refreshHandler}/>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th scope='col'>User Business Key</th>
                            <th scope='col'>Role</th>
                            <th scope='col'>Active</th>
                            <th scope='col'>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.projectUsers.map((user) => {
                            return (
                                <tr key={user.userKey}>
                                    <td>{user.userKey}</td>
                                    <td>{user.userRole}</td>
                                    <td>
                                        <input className='form-check-input'
                                            type='checkbox' value={user.active}
                                            id={user.userKey}
                                            defaultChecked={user.active}
                                        />
                                    </td>
                                    <td>
                                        <button className='btn btn-danger'
                                            onClick={(e) => {this.hideDeleteModal(
                                                e, true, user.userKey, this.props.projectBK);}}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>


                <div className={'table-header'}>
                    Client Users:
                </div>
                <UserAdd showLoader={this.props.showLoader} clientBK={this.props.clientBK} userType={'client'} refreshHandler={this.refreshHandler}/>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th scope='col'>User Business Key</th>
                            <th scope='col'>Role</th>
                            <th scope='col'>Active</th>
                            <th scope='col'>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.clientUsers.map((user) => {
                            return (
                                <tr key={user.userKey}>
                                    <td>{user.userKey}</td>
                                    <td>{user.userRole}</td>
                                    <td>
                                        <input className='form-check-input'
                                            type='checkbox' value={user.active}
                                            id={user.userKey}
                                            defaultChecked={user.active}
                                        />
                                    </td>
                                    <td>
                                        <button className='btn btn-danger'
                                            onClick={(e) => {this.hideDeleteModal(e, true, user.userKey);}}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Fragment>
        );
    }

}

UsersList.prototypes = {
    clients: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    clientBK: PropTypes.string.isRequired,
    projectBK: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    showLoader: PropTypes.func.isRequired
};
export default UsersList;
