import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import AuthService from '../../services/auth-service.js';
import ConfirmationModal from '../Modals/confirmation-modal.jsx';

import _ from 'lodash';
import './admin-styles.scss';

class CalculationsList extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            calculations: [],
            deleteModalActive: false,
            deleteModalText: 'Are you sure?',
            deleteCalculationBK: null
        };

        this.deleteCalculation = this.deleteCalculation.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
    }

    componentDidMount() {
        this.refreshCalculations();
    }

    componentDidUpdate(prevProps) {
        if (this.props.projectBK !== prevProps.projectBK) {
            this.refreshCalculations();
        }
    }

    refreshCalculations() {
        return window.FastcastService().getCalculations(AuthService.getToken(), this.props.projectBK).then((resp) => {
            this.setState({
                calculations: resp.data || []
            });
        });
    }

    deleteCalculation() {
        if (!_.isEmpty(this.state.deleteCalculationBK)) {
            return window.FastcastService().deleteCalculation(
                AuthService.getToken(), this.state.deleteCalculationBK).then(async () => {
                this.setState({
                    deleteModalActive: false
                });
                this.refreshCalculations();
            });
        }
    }

    hideDeleteModal(event, show, deleteCalculationBK) {
        this.setState({
            deleteModalActive: (show) ? true : false,
            deleteCalculationBK
        });
    }

    render() {
        return (
            <Fragment>
                <ConfirmationModal
                    isVisible={this.state.deleteModalActive}
                    modalText={this.state.deleteModalText}
                    yesHandler={this.deleteCalculation}
                    noHandler={this.hideDeleteModal}
                />

                <table className={'table'}>
                    <thead>
                        <tr>
                            <th scope='col'>Business Key</th>
                            <th scope='col'>Name</th>
                            <th scope='col'>Type</th>
                            <th scope='col'>Max Height</th>
                            <th scope='col'>Active</th>
                            <th scope='col'>Delete</th>
                            <th scope='col'>Metadata</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.calculations.map((calculation) => {
                            return (
                                <tr key={calculation.businessKey}>
                                    <td>{calculation.businessKey}</td>
                                    <td>{calculation.name}</td>
                                    <td>{calculation.calculationType}</td>
                                    <td>{calculation.maxHeight}</td>
                                    <td>
                                        <input className='form-check-input'
                                            type='checkbox' value={calculation.active}
                                            id={calculation.businessKey}
                                            defaultChecked={calculation.active}
                                        />
                                    </td>
                                    <td>
                                        <button className='btn btn-danger'
                                            onClick={(e)=> {this.hideDeleteModal(e, true, calculation.businessKey);}}>
                                        DELETE
                                        </button>
                                    </td>
                                    <td>{calculation.metadata}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </Fragment>
        );
    }
}

CalculationsList.prototypes = {
    projectBK: PropTypes.string.isRequired
};

export default CalculationsList;
