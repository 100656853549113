import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AuthService from '../../services/auth-service.js';
import {NotificationManager} from 'react-notifications';

import './admin-styles.scss';

class UserProjectAdd extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            projectBK: '',
            userBK: '',
            userRole: ''
        };
        this.projectDropdownChange = this.projectDropdownChange.bind(this);
        this.userDropdownChange = this.userDropdownChange.bind(this);
        this.userRoleDropdownChange = this.userRoleDropdownChange.bind(this);

        this.userBKChange = this.userBKChange.bind(this);
        this.submit = this.submit.bind(this);
    }
    componentDidUpdate() {

    }

    resetState() {
        this.setState({
            projectBK: '',
            userBK: '',
            userRole: ''
        });
    }

    updateClientBK() {
        if (!_.isEmpty(this.props.clientBK)) {
            this.setState({
                searchType: 'businessKey',
                userBK: this.props.clientBK
            });
        }
    }

    userBKChange(event) {
        let val = '';
        if (!_.isNil(event.target.value)) {
            val = event.target.value;
        }
        this.setState({
            userBK: val
        });
    }

    projectDropdownChange(event) {
        let val = '';
        if (event.target.value && event.target.value !== 'default') {
            val = event.target.value;
        }
        this.setState({
            projectBK: val
        });
    }

    userDropdownChange(event) {
        let val = '';
        if (event.target.value && event.target.value !== 'default') {
            val = event.target.value;
        }
        this.setState({
            userBK: val
        });
    }

    userRoleDropdownChange(event) {
        let val = '';
        if (event.target.value && event.target.value !== 'default') {
            val = event.target.value;
        }
        this.setState({
            userRole: val
        });
    }

    submit(event) {
        this.props.showLoader(true);
        event.stopPropagation();
        const {projectBK, userBK, userRole} = this.state;

        if (!_.isEmpty(projectBK) && !_.isEmpty(userBK) && !_.isEmpty(userRole)) {
            return window.FastcastService().postProjectUser(AuthService.getToken(),projectBK, userBK, userRole)
                .catch(() => {
                    NotificationManager.error(
                        'Please check if the user is valid.', '', 8000);
                }).finally(() => {
                    this.props.refreshHandler();
                    this.props.showLoader(false);
                    this.resetState();
                });
        }
    }
    render() {
        return (
            <Fragment>
                <div className='dropdown-selector wide'>
                    <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                            <select className='custom-select' id='inputGroupSelect01'
                                onChange={this.projectDropdownChange} value={this.state.projectBK}>
                                <option defaultValue='default'>Project Business Key</option>
                                {this.props.projectBKs.map((projectBK) => {
                                    return (<option value={projectBK} key={projectBK}>{projectBK}</option>);
                                })}
                            </select>
                        </div>
                        <div className='input-group-prepend'>
                            <select className='custom-select' id='inputGroupSelect01'
                                onChange={this.userDropdownChange} value={this.state.userBK}>
                                <option defaultValue='default'>User Business Key</option>
                                {_.map(this.props.users, 'userKey').map((userBK) => {
                                    return (<option value={userBK} key={userBK}>{userBK}</option>);
                                })}
                            </select>
                        </div>
                        <div className='input-group-prepend'>
                            <select className='custom-select' id='inputGroupSelect01'
                                disabled={_.isEmpty(this.state.userBK)}
                                onChange={this.userRoleDropdownChange} value={this.state.userRole}>
                                <option defaultValue='default'>User Role ...</option>
                                {AuthService.canDelegateRoles(
                                    _.find(this.props.users, {userKey: this.state.userBK})
                                ).map(role => {
                                    return (<option key={role} value={role}>{role}</option>);
                                })};
                            </select>
                        </div>
                        <div className='input-group-append'>
                            <button className='btn btn-primary' type='button'
                                disabled={!this.state.userRole || !this.state.userBK || !this.state.projectBK}
                                onClick={this.submit}>
                                Assign User
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

UserProjectAdd.prototypes = {
    userType: PropTypes.string.isRequired,
    clientBK: PropTypes.string.isRequired,
    refreshHandler: PropTypes.func.isRequired,
    projectBKs: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    showLoader: PropTypes.func.isRequired
};
export default UserProjectAdd;
