import React, { PureComponent, Fragment } from 'react';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router';
import _ from 'lodash';
import {PAGES} from '../../constants/app-constants.js';

import './projects.scss';
import '../Pages/styles.scss';
import Header from '../NavPanels/header.jsx';
import Footer from '../NavPanels/footer.jsx';
import Loader from '../Loader/loader.jsx';

import ProjectsSideBar from './projects-side-bar.jsx';
import AuthService from '../../services/auth-service.js';
import {NotificationManager} from 'react-notifications';

class Projects extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            projects: [],
            layoutProject: false
        };
        this.stubFunction = this.stubFunction.bind(this);
    }

    async componentDidMount() {
        AuthService.checkAuthentication();
        AuthService.checkAuthentication();
        if(!AuthService.isUserAuthenticated()) {
            return;
        }
        this.checkRoleAccess();
        this.checkIE();
        await this.fetchEntitledProject();
    }

    async fetchEntitledProject() {
        let projects = [];
        const clientsReq = await window.FastcastService().getClients(AuthService.getToken());
        const clients = clientsReq.data || [];
        const layoutProject = _.get(clients, ['0', 'metadata', 'layoutProject']) || false;
        if (AuthService.isClientAdmin() || AuthService.isSuperAdmin()) {
            const reqProjects = await window.FastcastService().getClientProjects(
                AuthService.getToken(), clients[0].businessKey);
            projects = reqProjects.data || [];
        } else {
            const projectsReq = await window.FastcastService().getUserProjects(AuthService.getToken());
            projects = projectsReq.data || [];
        }
        projects = _.sortBy(projects, 'name');
        this.setState({
            loading: false,
            projects,
            layoutProject
        });
    }

    checkRoleAccess() {
        !AuthService.hasMinimumReadRole() && this.props.history.push('/');
    }

    checkIE() {
        // Internet Explorer 6-11
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        isIE && NotificationManager.error('This page is not fully supported in Internet Explorer, please try in ' +
            'other browsers like Chrome, Firefox or Edge', '', 100000);
    }

    stubFunction() {
        // To reload the page with projects
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    render() {
        let renderBlock;
        if (!_.isEmpty(this.state.projects) && !this.state.loading) {
            renderBlock = (<Redirect exact from='/projects' to={{
                pathname: `/projects/${this.state.projects[0].businessKey}`,
                projects: this.state.projects,
                layoutProject: this.state.layoutProject
            }}/>);
        } else if (!this.state.loading) {
            renderBlock = (<Fragment>
                <div className={'app-container tools-screens'}>
                    <Header active={PAGES.TOOLS}/>
                    <div className={'component-container'}>
                        <div className={'project-container'}>
                            <ProjectsSideBar
                                projects = {[]}
                                activeProjectBK = {null}
                                selectedCalculationBKs = {[]}
                                projectCalculations = {{}}
                                createNewCalculation = {this.stubFunction}
                                fetchEntitledProjects = {this.stubFunction}
                                fetchProjectCalculations = {this.stubFunction}
                                history = {this.props.history}
                                renderLoader = {this.stubFunction}
                                setActiveProjectAndCalculation = {this.stubFunction}
                                layoutProject = {this.state.layoutProject}
                            />
                        </div>
                    </div>
                    <Footer active={PAGES.TOOLS}/>
                </div>
                <Loader loading={this.state.loading}/>
            </Fragment>);
        }

        return (
            <Fragment>
                {renderBlock}
            </Fragment>
        );
    }
}

export default withRouter(Projects);
