import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './admin-styles.scss';
import {withRouter} from 'react-router';

class AdminTopNav extends PureComponent {
    constructor(props) {
        super(props);
        this.updateView = this.updateView.bind(this);
    }

    updateView(viewName, event) {
        event.stopPropagation();
        if (this.props.viewName !== viewName) {
            switch (viewName) {
                case 'clients':
                    return this.props.history.push('/admin/clients');
                case 'projects':
                    if (this.props.match.params.clientBK) {
                        return this.props.history.push(`/admin/clients/${this.props.match.params.clientBK}/projects`);
                    }
                    return this.props.history.push('/admin/projects');
                case 'users':
                    if (this.props.match.params.clientBK && this.props.match.params.projectBK) {
                        return this.props.history.push(
                            `/admin/clients/${this.props.match.params.clientBK}/projects/${this.props.match.params.projectBK}/users`);
                    } else if (this.props.match.params.clientBK ) {
                        return this.props.history.push(
                            `/admin/clients/${this.props.match.params.clientBK}/users`);
                    }
                    break;
                case 'calculations':
                    if (this.props.match.params.clientBK && this.props.match.params.projectBK) {
                        return this.props.history.push(
                            `/admin/clients/${this.props.match.params.clientBK}/projects/${this.props.match.params.projectBK}/calculations`);
                    }
                    break;
                default:
                    return this.props.history.push(`/admin/${viewName}`);
            }
        }
    }
    render() {
        return(
            <table className={'top-nav'}>
                <tbody>
                    <tr>
                        <td className={classNames({
                            'active': this.props.viewName.includes('clients')
                        })} onClick={(event) => {this.updateView('clients', event);}}>
                            Clients
                        </td>
                        <td className={classNames({
                            'active': this.props.viewName.includes('projects')
                        })} onClick={(event) => {this.updateView('projects', event);}}>
                            Projects
                        </td>
                        {this.props.match.params.clientBK && this.props.match.params.projectBK &&
                            (<td className={classNames({
                                'active': this.props.viewName.includes('calculations')
                            })} onClick={(event) => {
                                this.updateView('calculations', event);
                            }}>
                                Calculations
                            </td>)
                        }
                        { this.props.match.params.clientBK &&
                            (<td className={classNames({
                                'active': this.props.viewName.includes('users')
                            })} onClick={(event) => {this.updateView('users', event);}}>
                                Users
                            </td>)
                        }
                    </tr>
                </tbody>
            </table>

        );
    }
}

AdminTopNav.prototypes = {
    viewName: PropTypes.string.isRequired

    // isVisible: PropTypes.bool.isRequired,
    // yesHandler: PropTypes.func.isRequired,
    // noHandler: PropTypes.func.isRequired,
    // modalText: PropTypes.string.isRequired
};

export default withRouter(AdminTopNav);
