import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './admin-styles.scss';
import _ from 'lodash';
import AuthService from '../../services/auth-service.js';
import classNames from 'classnames';

class ProjectAdd extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            name: ''
        };
        this.newProjectInputChange = this.newProjectInputChange.bind(this);
        this.submitNewProject = this.submitNewProject.bind(this);
    }

    newProjectInputChange(event, type) {
        let val = '';
        if (!_.isNil(event.target.value)) {
            val = event.target.value;
        }
        switch(type) {
            case 'name':
                this.setState({name: val});
                break;
            default:
                break;
        }
    }
    resetFields() {
        this.setState({
            name: ''
        });
    }
    submitNewProject() {
        const {name} = this.state;
        if (!_.isEmpty(name)) {
            return window.FastcastService().postProject(
                AuthService.getToken(), this.props.clientBK, name
            ).then(async () => {
                this.resetFields();
                this.props.hideForm(true);
                this.props.refreshProjects();
            });
        }
    }

    render() {
        return (
            <div className={classNames({
                'new-item-container project': true,
                'hide': !this.props.display
            })}>
                <div className={'input-div'}>
                    <span className={'label'}>Name:</span>
                    <input type='text' className='form-control' placeholder={'Project name'}
                        onChange={(e) => {this.newProjectInputChange(e, 'name');}} value={this.state.name}/>
                </div>
                <div className={'input-div'}>
                    <button type="button" className="btn btn-primary" onClick={this.submitNewProject}
                        disabled={!this.state.name}
                    >
                        Create a new project
                    </button>
                </div>
            </div>
        );
    }
}

ProjectAdd.prototypes = {
    clientBK: PropTypes.string.isRequired,
    display: PropTypes.bool.isRequired,
    hideForm: PropTypes.func.isRequired,
    refreshProjects: PropTypes.func.isRequired
};
export default ProjectAdd;
