import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import axios from 'axios';

import Header from '../NavPanels/header.jsx';
import Footer from '../NavPanels/footer.jsx';
import ConfigService from '../../services/config-service.js';
import {NotificationManager} from 'react-notifications';

import './contact.scss';
import {PAGES} from '../../constants/app-constants.js';

class Contact extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isFormValid: false
        };
        ConfigService.getConfig().then((config) => {
            this.setState({
                emailRecipient: config.emailRecipient,
                emailSendEndpoint: config.emailSendEndpoint,
                isEmailDisabled: false
            });
        });
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    handleChange() {
        const isValid = this.name && !_.isEmpty(this.name.value) &&
            this.email && !_.isEmpty(this.email.value) &&
            this.message && !_.isEmpty(this.message.value);

        if (this.state.isFormValid !== isValid) {
            this.setState({isFormValid: isValid});
        }
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit(event);
        }
    }

    handleSubmit(e) {
        this.state.isFormValid && this.sendEmail();
        e.preventDefault();
    }

    sendEmail() {
        const emailHtml = [];
        emailHtml.push('<pre style="font-size:15px;">');
        emailHtml.push('FROM: ' + this.name.value + ' <<strong>' + this.email.value);
        if (this.phone.value) {
            emailHtml.push(', ' + this.phone.value);
        }
        emailHtml.push('</strong>><br/></br/><br/>');
        emailHtml.push(this.message.value + '</pre>');
        const contactPayload = {
            subject: 'contacted from ' + window.location.origin,
            from: this.email.value,
            to: this.state.emailRecipient,
            message: emailHtml.join('')
        };

        this.setState({
            isEmailDisabled: true
        });
        this.handleChange(true);
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        return axios.post(this.state.emailSendEndpoint, contactPayload).then(() => {
            NotificationManager.success('Successfully delivered your message. We\'ll get back to you soon', '', 5000);
        }).catch((error) => {
            console.error('Error in sending email: ', error);
            NotificationManager.error(
                'Sorry, we failed to deliver your message. Please email: contact@fastcastcity.com', '', 30000);
        });
    }

    render() {
        return (
            <Fragment>
                <div className={'app-container'}>
                    <Header active={PAGES.CONTACT}/>
                    <div className={'component-container'}>
                        <div className='contact'>
                            <div className='header'>
                                <h2>CONTACT US</h2>
                            </div>
                            <div className='form'>
                                <form onSubmit={this.handleSubmit}
                                    onChange={this.handleChange}
                                    onKeyDown={this.onKeyDown}
                                >
                                    <div className='form-elem-group'>
                                        <label htmlFor='contact-name'>Name</label>
                                        <input type='text'
                                            autoComplete='name'
                                            name='name'
                                            required=''
                                            ref={(input) => this.name = input}
                                            maxLength='100'/>
                                    </div>
                                    <div className='form-elem-group'>
                                        <label htmlFor='contact-email'>Email Address</label>
                                        <input type='email'
                                            autoComplete='email'
                                            ref={(input) => this.email = input}
                                            name='email'
                                            required=''
                                            maxLength='100'/>
                                    </div>
                                    <div className='form-elem-group'>
                                        <label htmlFor='contact-email'>Phone Number <span
                                            className='light-text'>(optional)</span></label>
                                        <input type='tel'
                                            autoComplete='phone'
                                            ref={(input) => this.phone = input}
                                            name='phone'
                                            maxLength='20'/>
                                    </div>
                                    <div className='form-elem-group'>
                                        <label htmlFor='contact-message'>Message</label>
                                        <textarea name='message'
                                            autoComplete='message'
                                            ref={(input) => this.message = input}
                                            required=''
                                            maxLength='10000' />
                                    </div>
                                    <button className='btn btn-primary'
                                        type='submit'
                                        onSubmit={this.handleSubmit}
                                        disabled={!this.state.isFormValid || this.state.isEmailDisabled}
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer active={PAGES.CONTACT}/>
                </div>
            </Fragment>
        );
    }
}

export default Contact;
