import React, { PureComponent, Fragment } from 'react';
import {Redirect} from 'react-router-dom';

class Tools extends PureComponent {
    render() {
        return (
            <Fragment>
                <Redirect exact to={{
                    pathname: '/projects'
                }}/>
            </Fragment>
        );
    }
}

export default Tools;
