import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AuthService from '../../services/auth-service.js';

import './admin-styles.scss';

class ClientSelector extends PureComponent {
    constructor(props) {
        super(props);
        this.dropdownChange = this.dropdownChange.bind(this);
        this.searchTextChange = this.searchTextChange.bind(this);
        this.submit = this.submit.bind(this);

        this.state = {
            searchType: '',
            searchText: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.clientBK !== prevProps.clientBK) {
            this.updateClientBK();
        }
    }

    componentDidMount() {
        this.updateClientBK();
    }

    updateClientBK() {
        if (!_.isEmpty(this.props.clientBK)) {
            this.setState({
                searchType: 'businessKey',
                searchText: this.props.clientBK
            });
        }
    }

    searchTextChange(event) {
        let val = '';
        if (!_.isNil(event.target.value)) {
            val = event.target.value;
        }
        this.setState({
            searchText: val
        });
    }

    dropdownChange(event) {
        let val = '';
        if (event.target.value && event.target.value !== 'default') {
            val = event.target.value;
        }
        this.setState({
            searchType: val
        });
    }

    submit(event) {
        event.stopPropagation();

        if (!_.isEmpty(this.state.searchType) && !_.isEmpty(this.state.searchText)) {
            window.FastcastService().getClientByBusinessKey(AuthService.getToken(), this.state.searchText).then((resp) => {
                this.props.newClientHandler(resp);
            });
        }
    }
    render() {
        return (
            <Fragment>
                <div className='dropdown-selector'>
                    <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                            <select className='custom-select' id='inputGroupSelect01'
                                onChange={this.dropdownChange} value={this.state.searchType}>
                                <option defaultValue='default'>Find Client ...</option>
                                <option value='businessKey'>Clients Business key</option>
                                <option value='name' disabled>Clients Name</option>
                            </select>
                        </div>
                        <input type='text' className='form-control' aria-label='Text input with dropdown button'
                            onChange={this.searchTextChange} value={this.state.searchText}/>
                        <div className='input-group-append'>
                            <button className='btn btn-primary' type='button'
                                disabled={!this.state.searchType || !this.state.searchText} onClick={this.submit}>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

ClientSelector.prototypes = {
    clientBK: PropTypes.string.isRequired,
    newClientHandler: PropTypes.func.isRequired
};
export default ClientSelector;
